import { dataLayer } from "Static/variables";
export const addImpressionsGTM = ({ productsList, listInfo, }) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            impressions: productsList.map((product, idx) => ({
                brand: product.brand.name,
                category: product.category.name,
                id: product.id,
                list: listInfo,
                name: `${product.model}${product.model_full ? " " + product.model_full : ""}`,
                position: idx + 1,
                price: product.actual_price,
                variant: product.sku,
            })),
        },
    });
};
export const addToCartGTM = ({ product, actionFieldList, quantity, }) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            add: {
                actionField: { list: actionFieldList },
                products: [
                    {
                        brand: product.brand.name,
                        category: product.category.name,
                        id: product.id,
                        name: product.model + (product.model_full ? ` ${product.model_full}` : ""),
                        price: product.actual_price,
                        quantity,
                        variant: product.sku,
                    },
                ],
            },
        },
    });
};
export const removeFromCartGTM = ({ productsList, quantity, }) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            remove: {
                products: productsList.map((product) => ({
                    name: `${product.model}${product.model_full ? " " + product.model_full : ""}`,
                    id: product.id,
                    price: product.actual_price,
                    brand: product.brand.name,
                    category: product.category.name,
                    variant: product.sku,
                    quantity,
                })),
            },
        },
    });
};
export const addToWishlistGTM = ({ product, category, }) => {
    var _a, _b;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "add_to_wishlist",
        ecommerce: {
            currency: "RUB",
            items: [
                {
                    item_id: product.sku,
                    item_name: product.model + (product.model_full ? ` ${product.model_full}` : ""),
                    item_brand: ((_a = product === null || product === void 0 ? void 0 : product.brand) === null || _a === void 0 ? void 0 : _a.name) || typeof product.barcode === "string" ? product.brand : "",
                    item_category: (_b = product === null || product === void 0 ? void 0 : product.category) === null || _b === void 0 ? void 0 : _b.name,
                    ...(category && { item_list_name: `Catalog page - ${category}` }),
                    item_variant: product.barcode,
                    price: product.actual_price,
                    quantity: 1,
                },
            ],
        },
    });
};
export const checkoutInfoGTM = ({ productsList, cartData, }) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            checkout: {
                actionField: { step: "1", option: "cart" },
                products: productsList.map((product) => {
                    var _a;
                    return {
                        name: product.model,
                        id: product.id,
                        price: product.actual_price,
                        brand: product.brand.name,
                        category: product.category.name,
                        variant: product.sku,
                        quantity: ((_a = cartData.find((productInCart) => productInCart.productId === product.id)) === null || _a === void 0 ? void 0 : _a.sizes.length) || 1,
                    };
                }),
            },
        },
        "gtm-ee-event-category": "Enhanced Ecommerce",
        "gtm-ee-event-action": "Checkout Step 1",
        "gtm-ee-event-non-interaction": false,
    });
};
export const productClickGTM = ({ list, productsForSend, fullProductsList, }) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        currencyCode: "RUB",
        ecommerce: {
            click: {
                actionField: { list },
                products: productsForSend.map((product, idx) => {
                    var _a;
                    return ({
                        brand: ((_a = product.brand) === null || _a === void 0 ? void 0 : _a.name) || product.brand,
                        category: product.category_name || product.category.name,
                        id: product.id,
                        name: `${product.model}${product.model_full ? " " + product.model_full : ""}`,
                        position: fullProductsList.findIndex((currentProduct) => currentProduct.id === product.id) + 1 || idx + 1,
                        price: product.actual_price,
                        variant: product.sku,
                    });
                }),
            },
        },
    });
};
export const productDetailGTM = ({ product }) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            detail: {
                products: [
                    {
                        brand: product.brand.name,
                        category: product.category.name,
                        id: product.id,
                        name: `${product.model}${product.model_full ? " " + product.model_full : ""}`,
                        price: product.actual_price,
                        variant: product.sku,
                    },
                ],
            },
        },
    });
};
