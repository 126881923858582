import "./FavouritesHeart.scss";
import React, { useEffect, useState } from "react";
import { addProductToCartOrFavourites, removeProductFromCartOrFavourites } from "Redux/slices/cartSlice";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { addToWishlistGTM } from "Utils/analyticEvents";
import { HeartIcon } from "Svg/HeartIcon";
export const FavouritesHeart = ({ className, category = "", product, onClick }) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.userInfo);
    const { productsInFavourites } = useAppSelector((state) => state.cart);
    const [favouritesIds, setFavouritesIds] = useState([]);
    useEffect(() => {
        setFavouritesIds(productsInFavourites.map((product) => product.productId));
    }, [productsInFavourites]);
    const addToFavourites = (event, product) => {
        event.stopPropagation();
        const isRemove = favouritesIds.includes(product.id);
        if (isRemove) {
            dispatch(removeProductFromCartOrFavourites({
                removedProducts: [product.id],
                location: "favourites",
                needSyncWithDB: !!user,
            }));
        }
        else {
            dispatch(addProductToCartOrFavourites({
                newProducts: [{ productId: product.id, sizes: [] }],
                location: "favourites",
                needSyncWithDB: !!user,
            }));
            addToWishlistGTM({ product, category });
        }
        onClick && onClick();
    };
    return (React.createElement("div", { className: "favouritesHeart" + (className ? ` ${className}` : ""), onClick: (e) => addToFavourites(e, product) },
        React.createElement(HeartIcon, { pathStyle: {
                stroke: favouritesIds.includes(product.id) ? "var(--blue-500)" : "",
                fill: favouritesIds.includes(product.id) ? "var(--blue-500)" : "",
            } })));
};
