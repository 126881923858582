import "./PromoAction.scss";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeAuthModalVisibility } from "Redux/slices/appSlice";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { AdaptivePopup } from "Components/AdaptivePopup/AdaptivePopup";
export const PromoAction = ({ promoActionData }) => {
    const { breakpointMatches, deviceInfo, isAuthModalShown } = useAppSelector((state) => state.app);
    const { user } = useAppSelector((state) => state.userInfo);
    const location = useLocation();
    const [promosInSession, setPromosInSession] = useState(() => {
        const activePromosInSession = sessionStorage.getItem("promoActions");
        if (activePromosInSession) {
            return JSON.parse(activePromosInSession);
        }
        return [];
    });
    const [isPromoShown, setIsPromoShown] = useState(false);
    const [timeoutId, setTtimeoutId] = useState(0);
    useEffect(() => {
        const currentPromoInSession = promosInSession.find((promo) => promo.name === promoActionData.name);
        if (!currentPromoInSession || !currentPromoInSession.startTimerDone) {
            const updatedPromosForSession = promosInSession.filter((promo) => promo.name !== promoActionData.name);
            updatedPromosForSession.push({ startTimerDone: false, name: promoActionData.name, repeatTimerDone: false });
            setPromosInSession(updatedPromosForSession);
            sessionStorage.setItem("promoActions", JSON.stringify(updatedPromosForSession));
            const timoutId = setTimeout(() => {
                setIsPromoShown(true);
                const updatedPromosForSessionWithFirstTimer = updatedPromosForSession.map((promo) => {
                    if (promo.name === promoActionData.name) {
                        return { ...promo, startTimerDone: true };
                    }
                    return promo;
                });
                setPromosInSession(updatedPromosForSessionWithFirstTimer);
                sessionStorage.setItem("promoActions", JSON.stringify(updatedPromosForSessionWithFirstTimer));
            }, promoActionData.timer);
            setTtimeoutId(timoutId);
        }
    }, []);
    useEffect(() => {
        const currentPromoInSession = promosInSession.find((promo) => promo.name === promoActionData.name);
        if (!isPromoShown &&
            currentPromoInSession &&
            currentPromoInSession.startTimerDone &&
            !(currentPromoInSession === null || currentPromoInSession === void 0 ? void 0 : currentPromoInSession.repeatTimerDone)) {
            const timoutId = setTimeout(() => {
                setIsPromoShown(true);
                const updatedPromosForSessionWithFirstTimer = [...promosInSession].map((promo) => {
                    if (promo.name === promoActionData.name) {
                        return { ...promo, repeatTimerDone: true };
                    }
                    return promo;
                });
                setPromosInSession(updatedPromosForSessionWithFirstTimer);
                sessionStorage.setItem("promoActions", JSON.stringify(updatedPromosForSessionWithFirstTimer));
            }, promoActionData.repeatTimer);
            setTtimeoutId(timoutId);
        }
    }, [isPromoShown, promosInSession]);
    useEffect(() => {
        if (isAuthModalShown || location.pathname === (promoActionData === null || promoActionData === void 0 ? void 0 : promoActionData.url) || location.pathname.startsWith("/checkout/")) {
            clearTimeout(timeoutId);
        }
    }, [timeoutId, user, isAuthModalShown, location.pathname]);
    const closeHandler = () => {
        setIsPromoShown(false);
    };
    const [isImgLoading, setImgLoading] = useState(true);
    useEffect(() => {
        setImgLoading(true);
    }, [breakpointMatches[600]]);
    const isFirstOrderDiscount = useRef(promoActionData.name === "firstOrderDiscount");
    return (React.createElement(AdaptivePopup, { onClose: closeHandler, drawerClassName: 'promoAction__drawer', isPopupShown: isPromoShown, modalClassName: 'promoAction__modal', withDefaultPaddinsModal: false, withHeader: false },
        React.createElement("div", { className: `promoAction__${breakpointMatches[600] ? "drawer" : "modal"}__content` +
                (isImgLoading ? " skeletonLoading" : ""), style: {
                maxHeight: breakpointMatches[600]
                    ? deviceInfo.deviceType === "mobile"
                        ? document.documentElement.clientHeight - 56 + "px"
                        : "calc(100vh - 56px)"
                    : "",
            } },
            React.createElement(CloseIcon, { className: (breakpointMatches[600] ? "promoAction__closeIcon" : "customModal__content__header__closeIcon") +
                    (isImgLoading ? " promoAction__ui_hidden" : ""), onClick: closeHandler }),
            React.createElement("img", { className: `promoAction__${breakpointMatches[600] ? "drawer" : "modal"}__content__banner`, src: promoActionData[breakpointMatches[600] ? "mobileImg" : "desktopImg"], alt: 'promo banner', onLoad: () => setImgLoading(false) }),
            (isFirstOrderDiscount.current || promoActionData.url) && (React.createElement(ActionControl, { className: isImgLoading ? "promoAction__ui_hidden" : "", closeHandler: closeHandler, isAuthAction: !!isFirstOrderDiscount.current, isDrawer: !!breakpointMatches[600], url: promoActionData.url })))));
};
const ActionControl = ({ className, isAuthAction, isDrawer, closeHandler, url }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const actionHandler = () => {
        closeHandler();
        if (isAuthAction) {
            sessionStorage.setItem("fromDiscountPromo", "true");
            dispatch(changeAuthModalVisibility(true));
        }
        else if (url) {
            navigate(url);
        }
    };
    return (React.createElement("div", { className: "promoAction__actionControl-wrapper" + (className ? ` ${className}` : "") },
        React.createElement("button", { className: `promoAction__actionControl customBtn customBtn_contained customBtn${isDrawer ? "" : "_large"}`, onClick: actionHandler }, isAuthAction ? "Зарегистрироваться" : "Перейти в каталог")));
};
