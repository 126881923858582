import "./OptionalCarousel.scss";
import React, { useEffect, useState } from "react";
import { fetchApi } from "Utils/fetchApi";
import { IntersectionObserverWrapper } from "Components/IntersectionObserverWrapper/IntersectionObserverWrapper";
import { GoodsCarousel } from "Components/GoodsCarousel/GoodsCarousel";
export const OptionalCarousel = ({ category, className, deps = [], url }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCarouselShown, setIsCarouselShown] = useState(false);
    const [goodsData, setGoodsData] = useState([]);
    const [title, setTitle] = useState("");
    useEffect(() => {
        setIsLoading(true);
        isCarouselShown &&
            fetchApi({ method: "GET", url }).then(({ data }) => {
                setGoodsData(data.products || data.data);
                setTitle(data.title);
                setIsLoading(false);
            });
    }, [...deps, isCarouselShown]);
    if (!isLoading && goodsData.length === 0) {
        return null;
    }
    return (React.createElement("div", { className: "optionalCarousel" + (className ? ` ${className}` : "") },
        isLoading ? (React.createElement("div", { className: 'optionalCarousel__title_skeleton skeletonLoading' })) : (title && React.createElement("p", { className: 'optionalCarousel__title typography_title' }, title)),
        React.createElement("div", { className: 'optionalCarousel__slider' },
            React.createElement(IntersectionObserverWrapper, { querySelector: `.goodsCarousel_${category}`, observerCallback: () => setIsCarouselShown(true), children: React.createElement(GoodsCarousel, { categoryOfGoodsData: goodsData, category: category, isLoading: isLoading }) }))));
};
