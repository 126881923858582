import "./ManagerWidget.scss";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "Hooks/useAppSelector";
import { ChevronIcon } from "Svg/ChevronIcon";
import { AttachmentLinkIcon } from "Svg/AttachmentLinkIcon";
export const ManagerWidget = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAppSelector((state) => state.userInfo);
    const widgetRef = useRef();
    const [activeIcon, setActiveIcon] = useState(React.createElement(AttachmentLinkIcon, { className: 'managerWidget__icon' }));
    const [currentUrl, setCurrentUrl] = useState(location.pathname + location.search);
    useEffect(() => {
        const widget = document.querySelector(".managerWidget");
        if (widget && user && user.role.id !== "1") {
            widgetRef.current = widget;
            widget.classList.add("managerWidget_showed");
            setActiveIcon(React.createElement(AttachmentLinkIcon, { className: 'managerWidget__icon' }));
            checkUrl();
        }
        else if (widget) {
            widget.classList.remove("managerWidget_showed");
            widget.classList.remove("managerWidget_hidden");
        }
    }, [user]);
    useEffect(() => checkUrl(), [location]);
    const checkUrl = () => {
        const url = location.pathname + location.search;
        if (url !== currentUrl) {
            setCurrentUrl(url);
        }
        else {
            setTimeout(() => hideWidget(), 3000);
        }
    };
    const widgetHandler = () => {
        checkUrl();
        if (widgetRef.current.classList.contains("managerWidget_hidden")) {
            widgetRef.current.classList.remove("managerWidget_hidden");
            widgetRef.current.classList.add("managerWidget_showed");
            setActiveIcon(React.createElement(AttachmentLinkIcon, { className: 'managerWidget__icon' }));
        }
        else {
            navigate("/managerLinks/");
            hideWidget();
        }
    };
    const hideWidget = (event) => {
        var _a, _b;
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        (_a = widgetRef.current) === null || _a === void 0 ? void 0 : _a.classList.remove("managerWidget_showed");
        (_b = widgetRef.current) === null || _b === void 0 ? void 0 : _b.classList.add("managerWidget_hidden");
        setActiveIcon(React.createElement(ChevronIcon, { className: 'managerWidget__icon' }));
    };
    return (React.createElement("div", { className: 'managerWidget', onClick: widgetHandler },
        React.createElement("div", { className: 'managerWidget__content' }, activeIcon)));
};
