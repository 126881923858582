import { isBrowser } from "Utils/isBrowser";
export const reformatPrice = (price = 0) => {
    if (isBrowser() && window.Intl) {
        let result = new Intl.NumberFormat("ru-RU", {
            style: "currency",
            minimumFractionDigits: 0,
            currency: "RUB",
        }).format(+price);
        return result;
    }
    else {
        return `${price} руб.`;
    }
};
