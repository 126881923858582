import "./MobileAppQr.scss";
import React, { useState } from "react";
import { cdnHost } from "Static/variables";
import { MobileAppQrModal } from "Components/MobileAppQrModal/MobileAppQrModal";
export const MobileAppQr = () => {
    const [isQrModalShown, setIsQrModalShown] = useState(false);
    const closeHandler = () => setIsQrModalShown(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'mobileAppQr', onClick: () => setIsQrModalShown(true) },
            React.createElement("span", { className: 'typography_tec_16_default' }, "\u0421\u043A\u0430\u0447\u0438\u0432\u0430\u0439\u0442\u0435 \u043C\u043E\u0431\u0438\u043B\u044C\u043D\u043E\u0435 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435"),
            React.createElement("picture", null,
                React.createElement("source", { srcSet: `${cdnHost}/images/ls.net.webp`, type: 'image/webp' }),
                React.createElement("img", { src: `${cdnHost}/images/ls.net.jpg`, alt: 'app logo', loading: 'lazy' }))),
        React.createElement(MobileAppQrModal, { isShown: isQrModalShown, closeHandler: closeHandler })));
};
