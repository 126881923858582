import { useEffect, useState, useRef } from "react";
export const useCountdown = (expirationMs) => {
    const expirationMsRef = useRef(expirationMs);
    const [countDown, setCountDown] = useState(expirationMsRef.current - new Date().getTime());
    const [intervalId, setIntervalId] = useState();
    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(expirationMsRef.current - new Date().getTime());
        }, 1000);
        setIntervalId(interval);
        return () => clearInterval(interval);
    }, [expirationMsRef]);
    useEffect(() => {
        countDown <= 0 && clearInterval(intervalId);
    }, [countDown]);
    const oneSecondMs = 1000;
    const oneMinutMs = oneSecondMs * 60;
    const oneHourMs = oneMinutMs * 60;
    const oneDayMs = oneHourMs * 24;
    const secondsLeft = toTimeFormat(Math.floor((countDown % oneMinutMs) / 1000));
    const minutesLeft = toTimeFormat(Math.floor((countDown % oneHourMs) / oneMinutMs));
    const hoursLeft = toTimeFormat(Math.floor((countDown % oneDayMs) / oneHourMs));
    const daysLeft = toTimeFormat(Math.floor(countDown / oneDayMs));
    return { days: daysLeft, hours: hoursLeft, minutes: minutesLeft, seconds: secondsLeft };
};
const toTimeFormat = (time) => {
    if (time < 0) {
        return `00`;
    }
    else if (time < 10) {
        return `0${time}`;
    }
    return `${time}`;
};
