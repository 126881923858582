import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { changeAuthModalVisibility, changeSnackbarData } from "Redux/slices/appSlice";
export const AuthWrapper = ({ children }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuthModalShown } = useAppSelector((state) => state.app);
    const { user } = useAppSelector((state) => state.userInfo);
    const [authModalDisplayShown, setAuthModalDisplayShown] = useState(false);
    useEffect(() => {
        return () => setAuthModalDisplayShown(false);
    }, []);
    useEffect(() => {
        if (!user && authModalDisplayShown) {
            navigate("/");
        }
        else if (!user && !isAuthModalShown) {
            setAuthModalDisplayShown(true);
            dispatch(changeAuthModalVisibility(true));
            dispatch(changeSnackbarData({ message: "Страница только  для авторизованных пользователей", status: "error" }));
            window.scrollTo(0, 0);
        }
    }, [user, isAuthModalShown, location]);
    return user ? children : null;
};
