import "./NotificationCounter.scss";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { rewriteNotifications, rewriteUnvisitedOrders } from "Redux/slices/appSlice";
import { isBrowser } from "Utils/isBrowser";
const getWrappers = (type) => {
    const wrappers = isBrowser() ? window.document.querySelectorAll(`.notificationCounter_${type}`) : [];
    return [...wrappers];
};
export const NotificationCounter = ({ type, className }) => {
    const dispatch = useAppDispatch();
    const { notifications, unvisitedOrders } = useAppSelector((state) => state.app);
    const { productsInCart, productsInFavourites } = useAppSelector((state) => state.cart);
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        switch (type) {
            case "common":
                commonLogic();
                break;
            case "cart":
                cartLogic();
                break;
            case "orders":
                ordersLogic();
                break;
            case "favourites":
                favouritesLogic();
                break;
            default:
                break;
        }
    }, [counter, notifications, type, unvisitedOrders, productsInCart]);
    const total = notifications.reduce((accumulator, currentValue) => { var _a; return accumulator + ((_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.value) === null || _a === void 0 ? void 0 : _a.length); }, 0);
    useEffect(() => {
        type === "common" && setCounter(total);
    }, [total]);
    useEffect(() => {
        window.addEventListener("storage", commonStorageListener);
        return () => window.removeEventListener("storage", commonStorageListener);
    }, []);
    const commonLogic = () => {
        setCounter(total);
        const wrappers = getWrappers("common");
        wrappers.forEach((el) => (el.style.display = total === 0 ? "none" : "block"));
    };
    const commonStorageListener = (event) => {
        var _a;
        if (event.key === "notifications" && event.oldValue !== event.newValue && ((_a = event.storageArea) === null || _a === void 0 ? void 0 : _a.notifications)) {
            const newNotifications = JSON.parse(event.storageArea.notifications);
            const wrappers = getWrappers("common");
            dispatch(rewriteNotifications({ data: newNotifications }));
            const newCounter = newNotifications.reduce((accumulator, currentValue) => { var _a; return accumulator + ((_a = currentValue === null || currentValue === void 0 ? void 0 : currentValue.value) === null || _a === void 0 ? void 0 : _a.length); }, 0);
            setCounter(newCounter);
            if (newCounter > 0) {
                wrappers.forEach((el) => (el.innerHTML = `${newCounter}`));
            }
            else {
                wrappers.forEach((el) => (el.style.display = "none"));
            }
        }
    };
    const cartLogic = () => {
        setCounter(productsInCart.reduce((accum, product) => accum + (product.sizes.length || 1), 0));
        const savedLocalProducts = localStorage.getItem("cart");
        const counterNumber = savedLocalProducts ? JSON.parse(savedLocalProducts).length : 0;
        const wrappers = getWrappers("cart");
        if (counter + counterNumber === 0) {
            wrappers.forEach((el) => (el.style.display = "none"));
        }
        else if (counter > 0) {
            wrappers.forEach((el) => (el.style.display = "block"));
        }
        window.onstorage = (event) => {
            if (event.key === "cart" && event.oldValue !== event.newValue && event.storageArea.cart) {
                const newCounter = JSON.parse(event.storageArea.cart).length;
                setCounter(newCounter);
                if (!!newCounter) {
                    wrappers.forEach((el) => (el.style.display = "block"));
                }
                else {
                    wrappers.forEach((el) => (el.style.display = "none"));
                }
            }
        };
    };
    useEffect(() => {
        if (type === "orders") {
            window.addEventListener("storage", ordersStorageListener);
        }
        return () => window.removeEventListener("storage", ordersStorageListener);
    }, []);
    const ordersLogic = () => {
        setCounter(unvisitedOrders);
        const wrappers = getWrappers("orders");
        wrappers.forEach((el) => (el.style.display = unvisitedOrders > 0 ? "block" : "none"));
    };
    const ordersStorageListener = (event) => {
        var _a;
        if (event.key === "unvisitedOrders" && event.oldValue !== event.newValue && ((_a = event.storageArea) === null || _a === void 0 ? void 0 : _a.unvisitedOrders)) {
            const newUnvisitedOrders = +JSON.parse(event.storageArea.unvisitedOrders);
            const wrappers = getWrappers("orders");
            dispatch(rewriteUnvisitedOrders(newUnvisitedOrders));
            setCounter(newUnvisitedOrders);
            if (newUnvisitedOrders > 0) {
                wrappers.forEach((el) => (el.innerHTML = `${newUnvisitedOrders}`));
            }
            else {
                wrappers.forEach((el) => (el.style.display = "none"));
            }
        }
    };
    const favouritesLogic = () => {
        setCounter(productsInFavourites.length);
        const savedLocalProducts = localStorage.getItem("favourites");
        const counterNumber = savedLocalProducts ? JSON.parse(savedLocalProducts).length : 0;
        const wrappers = getWrappers("favourites");
        if (!counter || !counterNumber) {
            wrappers.forEach((el) => (el.style.display = "none"));
        }
        else {
            wrappers.forEach((el) => (el.style.display = "block"));
        }
        window.onstorage = (event) => {
            if (event.key === "favourites" && event.oldValue !== event.newValue && event.storageArea.favourites) {
                const newCounter = JSON.parse(event.storageArea.favourites).length;
                setCounter(newCounter);
                if (newCounter) {
                    wrappers.forEach((el) => (el.style.display = "none"));
                }
            }
        };
    };
    useEffect(() => {
        type === "favourites" && setCounter(productsInFavourites.length);
    }, [productsInFavourites.length]);
    return (React.createElement("div", { className: `notificationCounter notificationCounter_${type}` + (className ? " " + className : "") }, counter));
};
