import { isBrowser } from "./isBrowser";
export const searchTrackingForYM = () => {
    var _a;
    if (isBrowser()) {
        if ("yaCounter10626637" in window) {
            (_a = window === null || window === void 0 ? void 0 : window.yaCounter10626637) === null || _a === void 0 ? void 0 : _a.reachGoal("anyQuerySearch");
        }
        else {
            console.error("YM not found");
        }
    }
};
