import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector";
import { addNotification, removeNotificaionCategory } from "Redux/slices/appSlice";
export const useIncreaseNotification = () => {
    const dispatch = useDispatch();
    const { notifications, discountIncrease } = useAppSelector((state) => state.app);
    const { user, isUserTypeDefined } = useAppSelector((state) => state.userInfo);
    const isNeedShown = useMemo(() => {
        const currentNotification = notifications
            ? notifications.find((notification) => notification.category === "discountIncrease")
            : undefined;
        const lastTimeStamp = localStorage.getItem("lastTimeStampOfDiscountIncrease");
        const currentTimeStamp = new Date().toISOString().split("T")[0];
        const isNewNotice = discountIncrease && !currentNotification && !lastTimeStamp;
        const isNoticeNotShownToday = (discountIncrease && lastTimeStamp && new Date(currentTimeStamp) > new Date(lastTimeStamp)) || false;
        if (isNewNotice || isNoticeNotShownToday)
            return true;
        return false;
    }, [discountIncrease, notifications]);
    const isNeedUpdate = useMemo(() => {
        const currentNotification = notifications
            ? notifications.find((notification) => notification.category === "discountIncrease")
            : undefined;
        if ((currentNotification === null || currentNotification === void 0 ? void 0 : currentNotification.value[0]) && discountIncrease) {
            const { percent, dateTo } = currentNotification === null || currentNotification === void 0 ? void 0 : currentNotification.value[0].content;
            return percent !== discountIncrease.percent || dateTo !== discountIncrease.dateTo;
        }
        return false;
    }, [discountIncrease, notifications]);
    useEffect(() => {
        if (discountIncrease === undefined || !isUserTypeDefined)
            return;
        if (discountIncrease === null || (user === null || user === void 0 ? void 0 : user.discountIncrease) === null) {
            dispatch(removeNotificaionCategory("discountIncrease"));
            localStorage.removeItem("lastTimeStampOfDiscountIncrease");
        }
        function showNotice() {
            dispatch(removeNotificaionCategory("discountIncrease"));
            const isCatalogUrl = ["/categories", "/brands", "/catalog", "/specials"].some((url) => location.pathname.startsWith(url));
            !isCatalogUrl &&
                dispatch(addNotification({
                    category: "discountIncrease",
                    id: Date.now().toString(),
                    content: discountIncrease,
                }));
            localStorage.removeItem("lastTimeStampOfDiscountIncrease");
        }
        isNeedUpdate ? showNotice() : isNeedShown ? setTimeout(showNotice, 10000) : null;
    }, [isUserTypeDefined, discountIncrease]);
};
