import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import "./AppNotify.scss";
import React from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeAppNotifyVisibility } from "Redux/slices/appSlice";
export const AppNotify = () => {
    const dispatch = useAppDispatch();
    const { appNotify } = useAppSelector((state) => state.app);
    const closeBanner = (confirm) => {
        localStorage.setItem("appNotify", Math.round(new Date().getTime() / 1000 + (confirm ? 7776000 : 1296000)).toString());
        dispatch(changeAppNotifyVisibility(false));
    };
    return appNotify ? (React.createElement("div", { className: 'appNotify' },
        React.createElement("button", { "aria-label": 'close icon', onClick: () => closeBanner(false) },
            React.createElement(CloseIcon, null)),
        React.createElement("div", { className: 'appNotify__text' },
            React.createElement("span", null, "LS.NET.RU"),
            React.createElement("p", null, "\u0417\u0410\u0413\u0420\u0423\u0417\u0418\u0422\u042C \u0432 Google Play")),
        React.createElement("a", { href: 'https://play.google.com/store/apps/details?id=com.lsboutqiue.app', "aria-label": '\u0421\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435' },
            React.createElement("button", { className: 'appNotify__btn customBtn customBtn_contained customBtn_small', onClick: () => closeBanner(true) }, "\u0421\u043C\u043E\u0442\u0440\u0435\u0442\u044C")))) : null;
};
