import "./PopularCategories.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import { SkeletonsList } from "Components/SkeletonsList/SkeletonsList";
export const PopularCategories = ({ className, isLoading, popularCategoriesData }) => {
    return (React.createElement("div", { className: "popularCategories" + (className ? ` ${className}` : "") },
        React.createElement("p", { className: 'popularCategories__title typography_title' }, "\u041F\u043E\u043F\u0443\u043B\u044F\u0440\u043D\u044B\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438"),
        React.createElement("div", { className: "popularCategories__content" + (isLoading ? " popularCategories__content_skeletonLayout" : "") }, isLoading ? (React.createElement(SkeletonsList, { quantity: 10 },
            React.createElement("div", { className: 'popularCategories__item_skeleton skeletonLoading' }))) : (popularCategoriesData.map((category) => (React.createElement(NavLink, { className: 'popularCategories__item skeletonLoading', key: category.id, to: category.url, "aria-label": `Сatalog category - ${category.name}`, onLoad: (e) => e.currentTarget.classList.remove("skeletonLoading") },
            React.createElement("img", { src: category.image, alt: `Preview for ${category.name} category`, loading: 'lazy' }))))))));
};
