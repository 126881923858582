import "./CloseIcon.scss";
import React from "react";
export const CloseIcon = ({ className, isDefaultClosePosition = false, onClick, size = "regular", style, withBackground, }) => {
    return (React.createElement("div", { className: "closeIcon-wrapper" +
            (isDefaultClosePosition ? " closeIcon-wrapper_defaultClosePosition" : "") +
            (className ? ` ${className}` : ""), style: style, "data-size": size, onClick: onClick },
        React.createElement("div", { className: "closeIcon" + ` closeIcon_${size}` + (withBackground ? " closeIcon_withBackground" : "") },
            React.createElement("div", { className: "closeIcon__item" + ` closeIcon__item_${size}` }),
            React.createElement("div", { className: "closeIcon__item" + ` closeIcon__item_${size}` }))));
};
