import React, { useEffect, useRef, useState } from "react";
export const IntersectionObserverWrapper = ({ querySelector, observerCallback, wasOnScreen = false, children, options = {}, }) => {
    const wrapperRef = useRef(null);
    const mutationObserverRef = useRef(null);
    const intersectionObserverRef = useRef(null);
    const [isComponentWasOnScreen, setIsComponentWasOnScreen] = useState(wasOnScreen);
    const [observableEl, setObservableEl] = useState(null);
    useEffect(() => {
        const DOMEl = document.querySelector(querySelector);
        if (DOMEl) {
            setObservableEl(DOMEl);
        }
        else {
            mutationObserverRef.current = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    var _a;
                    const target = mutation.target;
                    if ((target === null || target === void 0 ? void 0 : target.classList.contains(querySelector.substring(1))) ||
                        ((_a = target === null || target === void 0 ? void 0 : target.firstChild) === null || _a === void 0 ? void 0 : _a.classList.contains(querySelector.substring(1)))) {
                        setObservableEl(document.querySelector(querySelector));
                    }
                });
            });
            mutationObserverRef.current.observe(wrapperRef.current, {
                childList: true,
            });
        }
        return () => {
            mutationObserverRef.current && mutationObserverRef.current.disconnect();
        };
    }, []);
    useEffect(() => {
        intersectionObserverRef.current = new IntersectionObserver(intersectionHandler, options);
        observableEl && intersectionObserverRef.current && intersectionObserverRef.current.observe(observableEl);
        return () => {
            observableEl && intersectionObserverRef.current && intersectionObserverRef.current.unobserve(observableEl);
        };
    }, [observableEl]);
    useEffect(() => {
        isComponentWasOnScreen && observerCallback();
    }, [isComponentWasOnScreen]);
    const intersectionHandler = (entries) => {
        entries.forEach((entry) => entry.isIntersecting && !isComponentWasOnScreen && setIsComponentWasOnScreen(true));
    };
    return React.createElement("div", { ref: wrapperRef }, children);
};
