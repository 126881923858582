import "./LocationSelect.scss";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeCity } from "Redux/slices/appSlice";
import { saveUserData } from "Redux/slices/userInfoSlice";
import { fetchApi } from "Utils/fetchApi";
import { CustomModal } from "Components/CustomModal/CustomModal";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { CitySelectionInput } from "Components/CitySelectionInput/CitySelectionInput";
import { SearchIcon } from "Svg/SearchIcon";
import { CheckIcon } from "Svg/CheckIcon";
import { Loader } from "Components/Loader/Loader";
export const LocationSelect = ({ isGlobalChange = true, onCancel, onChange, userCity, visible, }) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.userInfo);
    const [searchedCityName, setSearchedCityName] = useState((userCity === null || userCity === void 0 ? void 0 : userCity.city_name) || "");
    const [newCity, setNewCity] = useState(userCity);
    useEffect(() => {
        if (userCity && visible) {
            setNewCity(userCity);
            setSearchedCityName(userCity.city_name);
        }
    }, [userCity, visible]);
    const saveSelectedCity = () => {
        if (newCity && isGlobalChange) {
            dispatch(changeCity(newCity));
            updateCityInDB(newCity.city_id);
        }
        onChange && newCity && onChange(newCity);
        closeModalHandler();
    };
    const saveDefaultCity = (city) => {
        setNewCity(city);
        if (city.city_id && isGlobalChange) {
            dispatch(changeCity(city));
            updateCityInDB(city.city_id);
        }
        onChange && city && onChange(city);
        closeModalHandler();
    };
    const closeModalHandler = () => {
        onCancel();
        setNewCity(null);
    };
    const updateCityInDB = (cityId) => {
        user &&
            (userCity === null || userCity === void 0 ? void 0 : userCity.city_id) &&
            userCity.city_id.toString() !== cityId.toString() &&
            fetchApi({
                method: "POST",
                url: "/user/update",
                body: {
                    city_id: cityId,
                },
            }).then(({ data }) => dispatch(saveUserData(data)));
    };
    const [defaultCities, setDefaultCities] = useState([]);
    useEffect(() => {
        visible &&
            defaultCities.length === 0 &&
            fetchApi({ method: "GET", url: "/cities/main" }).then(({ data }) => setDefaultCities(data));
    }, [visible]);
    return (React.createElement(CustomModal, { className: 'locationSelect', fullscreen: false, open: visible, onClose: closeModalHandler },
        React.createElement("div", { className: 'customModal__content__header' },
            React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', onClick: closeModalHandler }),
            React.createElement("span", { className: 'typography_h1' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0433\u043E\u0440\u043E\u0434")),
        React.createElement("div", { className: 'customModal__content__body' },
            React.createElement(CitySelectionInput, { onBlurHandler: saveSelectedCity, placeholder: '\u0412\u0430\u0448 \u0433\u043E\u0440\u043E\u0434', searchedCityName: searchedCityName, setNewCity: setNewCity, setSearchedCityName: setSearchedCityName, suffixIcon: React.createElement(SearchIcon, null), userCity: userCity }),
            defaultCities.length > 0 ? (React.createElement("ul", { className: 'locationSelect__links' }, defaultCities.map((defaultCity) => (React.createElement("li", { className: 'locationSelect__links__item typography_tec_16_default', key: defaultCity.city_id, onClick: () => saveDefaultCity(defaultCity) },
                React.createElement("span", null, defaultCity.city_name),
                (newCity === null || newCity === void 0 ? void 0 : newCity.city_id) === (defaultCity === null || defaultCity === void 0 ? void 0 : defaultCity.city_id) && React.createElement(CheckIcon, null)))))) : (React.createElement(Loader, { className: 'locationSelect__defaultCitiesLoader' })))));
};
