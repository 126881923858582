import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { personalAreaRoutes } from "Static/personalAreaRoutes";
import { NotificationCounter } from "Components/NotificationCounter/NotificationCounter";
import { UserIcon } from "Svg/UserIcon";
export const UserControl = ({ className, openAuthModal, user }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isUserTypeDefined } = useAppSelector((state) => state.userInfo);
    const personalAreaHandler = () => {
        if (isUserTypeDefined && user) {
            navigate("/personalArea/");
            !personalAreaRoutes.some((route) => location.pathname.startsWith(route)) &&
                localStorage.setItem("prevUrl", location.pathname + location.search);
        }
        else if (isUserTypeDefined) {
            openAuthModal();
        }
    };
    return (React.createElement("div", { className: "header__top__icons__userAvatar" + (className ? ` ${className}` : ""), onClick: personalAreaHandler },
        user ? (React.createElement("img", { src: user.photo, alt: 'User avatar' })) : (React.createElement(UserIcon, { className: 'header__top__icons__userAvatar__icon' })),
        React.createElement(NotificationCounter, { className: 'header__top__icons__counter', type: 'orders' })));
};
