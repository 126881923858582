import "./GoodsCarouselFallback.scss";
import React, { useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAppSelector } from "Hooks/useAppSelector";
export const GoodsCarouselFallback = ({ category, intersectionObserverClassName, withTitle = true, }) => {
    const { breakpointMatches } = useAppSelector((state) => state.app);
    const [numberOfSlides, setNumberOfSlides] = useState("auto");
    const [paddingOfSlides, setPaddingOfSlides] = useState(16);
    useLayoutEffect(() => {
        breakpointMatches[1440] ? setNumberOfSlides(3) : setNumberOfSlides(4);
        breakpointMatches[960] && setNumberOfSlides("auto");
        setPaddingOfSlides(breakpointMatches[600] ? 8 : 16);
    }, [breakpointMatches]);
    return (React.createElement("div", { className: "goodsCarousel_skeleton" +
            (intersectionObserverClassName ? ` ${intersectionObserverClassName}` : "") +
            (category ? ` goodsCarousel_${category}` : "") },
        withTitle && React.createElement("div", { className: 'goodsCarousel_skeleton__title skeletonLoading' }),
        React.createElement(Swiper, { className: 'goodsCarousel__track_skeleton', spaceBetween: paddingOfSlides, slidesPerView: numberOfSlides }, [...Array(4)].map((_, idx) => (React.createElement(SwiperSlide, { key: idx },
            React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton' },
                React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton__preview skeletonLoading' }),
                React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton__brand skeletonLoading' }),
                React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton__name skeletonLoading' }),
                React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton__price skeletonLoading' }))))))));
};
