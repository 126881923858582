import "./IncreasedDiscountTimer.scss";
import React, { useEffect } from "react";
import { useCountdown } from "Hooks/useCountdown";
import { declOfNum } from "Utils/declOfNum";
export const IncreasedDiscountTimer = ({ className, discountPercent, expirationDate, onTimerEnd, }) => {
    const { days, hours, minutes, seconds } = useCountdown(new Date(expirationDate).getTime());
    const numberOfDays = +days;
    const numberOfHours = +hours;
    useEffect(() => {
        if (+days + +hours + +minutes + +seconds <= 0) {
            onTimerEnd && onTimerEnd();
        }
    }, [days, hours, minutes, seconds]);
    const getTimer = () => {
        if (numberOfDays === 0)
            return `на ${hours}:${minutes}:${seconds}`;
        const defaultRow = `на ${numberOfDays} ${declOfNum(numberOfDays, ["день", "дня", "дней"])}`;
        return numberOfHours === 0
            ? defaultRow
            : defaultRow + ` ${numberOfHours} ${declOfNum(numberOfHours, ["час", "часа", "часов"])}`;
    };
    return (React.createElement("div", { className: "increasedDiscountTimer" + (className ? ` ${className}` : "") },
        React.createElement("span", { className: 'increasedDiscountTimer__label typography_h4' }, `+${discountPercent}%`),
        React.createElement("div", { className: 'typography_captionTxt' }, getTimer())));
};
