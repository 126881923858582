import "./Notifications.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { removeNotificaionCategory } from "Redux/slices/appSlice";
import { declOfNum } from "Utils/declOfNum";
import { DeliveryBoxIcon } from "Svg/DeliveryBoxIcon";
import { InfoIcon } from "Svg/InfoIcon";
import { DiscountIcon } from "Svg/DiscountIcon";
import { DocumentIcon } from "Svg/DocumentIcon";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { IncreasedDiscountTimer } from "Components/IncreasedDiscountTimer/IncreasedDiscountTimer";
export const Notifications = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { notifications, isHeaderShown } = useAppSelector((state) => state.app);
    const { user } = useAppSelector((state) => state.userInfo);
    const [notificationData, setNotificationsData] = useState([]);
    useEffect(() => setNotificationsData(notifications), [notifications]);
    const closeHandler = (categoryName) => {
        dispatch(removeNotificaionCategory(categoryName));
        if (categoryName === "discountIncrease") {
            localStorage.setItem("lastTimeStampOfDiscountIncrease", new Date().toISOString().split("T")[0]);
        }
    };
    const getIcon = (category) => {
        switch (category) {
            case "orders": {
                return React.createElement(DeliveryBoxIcon, null);
            }
            case "vpnWarning": {
                return React.createElement(InfoIcon, null);
            }
            case "discount": {
                return React.createElement(DiscountIcon, null);
            }
            case "partPay": {
                return React.createElement(DocumentIcon, null);
            }
            default: {
                return null;
            }
        }
    };
    const redirectToOrderPage = (categoryName, notificationId) => {
        if (user && (categoryName === "orders" || categoryName === "partPay")) {
            closeHandler(categoryName);
            navigate(`/orders/${notificationId}/`);
        }
    };
    const redirectToDiscountPage = () => {
        closeHandler("discountIncrease");
        navigate("/myDiscount/");
    };
    return notificationData.length > 0 ? (React.createElement("div", { className: 'notifications__wrapper', "data-is_header_shown": isHeaderShown }, notificationData.map((notifictionList) => (React.createElement("div", { className: 'notifications__category', key: notifictionList.category, "data-category": notifictionList.category },
        React.createElement(CloseIcon, { className: 'notifications__category__closeIcon', isDefaultClosePosition: true, onClick: () => closeHandler(notifictionList.category) }),
        notifictionList.value.map((notification) => (React.createElement("div", { className: 'notifications__category__item', key: notification.id },
            React.createElement("div", { className: 'notifications__category__item__icon' }, getIcon(notifictionList.category)),
            React.createElement("div", { className: 'notifications__category__item__text' }, notifictionList.category === "orders" ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'notifications__category__item__title typography_h4' }, "\u0412\u0430\u0448 \u0437\u0430\u043A\u0430\u0437 \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D"),
                React.createElement("span", { className: "typography_captionTxt" + (user ? " customLink" : ""), onClick: () => redirectToOrderPage(notifictionList.category, notification.id) }, `В заказе ${notification.content} ${declOfNum(notification.content, [
                    "товар",
                    "товара",
                    "товаров",
                ])}`))) : notifictionList.category === "certificates" ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'notifications__category__item__title typography_h4' }, "\u0421\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D"),
                React.createElement("span", { className: 'typography_captionTxt' }, "\u0411\u0430\u043B\u0430\u043D\u0441 \u0438 \u043F\u0440\u0438\u043C\u0435\u043D\u0435\u043D\u0438\u0435 \u0432 \u043A\u043E\u0440\u0437\u0438\u043D\u0435"))) : notifictionList.category === "vpnWarning" ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'notifications__category__item__title typography_h4', "data-is_warning": true }, "\u0423 \u0432\u0430\u0441 \u0432\u043A\u043B\u044E\u0447\u0435\u043D VPN!"),
                React.createElement("span", { className: 'typography_captionTxt' }, "\u0423\u0432\u0430\u0436\u0430\u0435\u043C\u044B\u0439 \u043A\u043B\u0438\u0435\u043D\u0442 - \u0432\u044B\u043A\u043B\u044E\u0447\u0438\u0442\u0435 VPN, \u0442\u043E\u0433\u0434\u0430 \u0432\u044B \u0441\u043C\u043E\u0436\u0435\u0442\u0435 \u0443\u0432\u0438\u0434\u0435\u0442\u044C \u0431\u043E\u043B\u044C\u0448\u0435 \u0442\u043E\u0432\u0430\u0440\u043E\u0432."))) : notifictionList.category === "discount" ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'notifications__category__item__title typography_h4' }, "\u0412\u044B \u043F\u043E\u043B\u0443\u0447\u0438\u043B\u0438 \u0441\u043A\u0438\u0434\u043A\u0443!"),
                React.createElement("span", { className: 'typography_captionTxt' }, "\u0423\u0432\u0430\u0436\u0430\u0435\u043C\u044B\u0439 \u043A\u043B\u0438\u0435\u043D\u0442, \u0412\u044B \u043F\u043E\u043B\u0443\u0447\u0438\u043B\u0438 \u0441\u043A\u0438\u0434\u043A\u0443 5% \u043D\u0430 \u043F\u0435\u0440\u0432\u044B\u0439 \u0437\u0430\u043A\u0430\u0437."))) : notifictionList.category === "discountIncrease" ? (React.createElement("div", { className: 'notifications__category__item__text__discountIncrease' },
                React.createElement(IncreasedDiscountTimer, { discountPercent: notification.content.percent, expirationDate: notification.content.dateTo, onTimerEnd: () => closeHandler("discountIncrease") }),
                React.createElement("div", { className: 'notifications__category__item__text__discountIncrease__description' },
                    React.createElement("span", { className: 'typography_tec_16_default' }, `Добавили +${notification.content.percent}% к вашей скидке`),
                    user && (React.createElement("button", { className: 'typography_tec_16_default', onClick: redirectToDiscountPage }, "\u041F\u041E\u0414\u0420\u041E\u0411\u041D\u0415\u0415"))))) : notifictionList.category === "partPay" ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'notifications__category__item__title typography_h4' }, "\u0412\u0430\u0448 \u0441\u0447\u0435\u0442 \u043F\u043E \u043E\u043F\u043B\u0430\u0442\u0435 \u0420\u0421 \u0432\u044B\u0441\u0442\u0430\u0432\u043B\u0435\u043D"),
                React.createElement("span", { className: "typography_captionTxt" + (user ? " customLink" : ""), onClick: () => redirectToOrderPage(notifictionList.category, notification.id) }, "\u0421\u043A\u0430\u0447\u0430\u0442\u044C \u0432 \u0441\u0432\u043E\u0435\u043C \u0437\u0430\u043A\u0430\u0437\u0435"))) : null))))))))) : null;
};
