import { createSlice } from "@reduxjs/toolkit";
import { getUniqueAndConcatedLocalData } from "Utils/getUniqueAndConcatedLocalData";
import localStorage from "Utils/localStorage";
import { fetchApi } from "Utils/fetchApi";
let localCart = localStorage.getItem("cart") || "[]";
if (localCart.indexOf("wishlist_count") > -1 || localCart.indexOf("items") > -1) {
    localCart = "[]";
    localStorage.removeItem("cart");
}
const uniqCart = getUniqueAndConcatedLocalData(JSON.parse(localCart));
localCart = uniqCart;
localStorage.setItem("cart", JSON.stringify(uniqCart));
let localFavourites = JSON.parse(localStorage.getItem("favourites") || "[]");
const uniqFavourites = getUniqueAndConcatedLocalData(localFavourites);
localFavourites = uniqFavourites;
localStorage.setItem("favourites", JSON.stringify(uniqFavourites));
const initialState = {
    productsInCart: localCart,
    productsInFavourites: localFavourites,
    showFavouritesIndicator: localFavourites.length > 0 && localStorage.getItem("showFavouritesIndicator") === "true",
};
export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addProductToCartOrFavourites(state, action) {
            const { location, newProducts, needSyncWithDB, isSoftAdding, skipFavouritesIndicator } = action.payload;
            const localProducts = localStorage.getItem(location);
            const currentProducts = JSON.parse(localProducts || "[]");
            const checkProductIds = newProducts.map((product) => product.productId);
            let result = [];
            if (isSoftAdding) {
                let updatedNewProducts = [...newProducts].map((product) => ({
                    productId: product.productId,
                    sizes: [...product.sizes].sort((a, b) => (a > b ? 1 : -1)),
                }));
                updatedNewProducts = newProducts.map((newProduct) => {
                    const unicSizes = new Set(newProduct.sizes);
                    const currentProduct = currentProducts.filter((current) => current.productId === newProduct.productId)[0];
                    currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.sizes.forEach((size) => unicSizes.add(size));
                    const sizes = Array.from(unicSizes).sort((a, b) => (a > b ? 1 : -1));
                    return { productId: newProduct.productId, sizes };
                });
                const filterredProducts = currentProducts.filter((product) => !checkProductIds.includes(product.productId));
                result = [...updatedNewProducts, ...filterredProducts];
            }
            else {
                let hardResult = [...currentProducts];
                newProducts.forEach((newProduct) => {
                    const idx = currentProducts.findIndex((currentProduct) => currentProduct.productId === newProduct.productId);
                    if (idx >= 0) {
                        hardResult.splice(idx, 1, newProduct);
                    }
                    else {
                        hardResult = [...hardResult, newProduct];
                    }
                    result = hardResult;
                });
            }
            if (location === "cart") {
                state.productsInCart = result;
            }
            else {
                if (result.length > 0 && !skipFavouritesIndicator) {
                    localStorage.setItem("showFavouritesIndicator", "true");
                    state.showFavouritesIndicator = true;
                }
                state.productsInFavourites = result;
            }
            localStorage.setItem(location, JSON.stringify(result));
            needSyncWithDB &&
                fetchApi({
                    method: "POST",
                    url: `/${location === "cart" ? "cart" : "wishlist"}/save`,
                    body: {
                        items: result,
                        saveHard: true,
                    },
                });
        },
        removeProductFromCartOrFavourites(state, action) {
            const { location, needSyncWithDB, removedProducts } = action.payload;
            const currentProducts = JSON.parse(localStorage.getItem(location) || "[]");
            const filterredProducts = currentProducts.filter((product) => !removedProducts.includes(product.productId));
            if (location === "cart") {
                state.productsInCart = filterredProducts;
            }
            else {
                state.productsInFavourites = filterredProducts;
                if (filterredProducts.length === 0) {
                    localStorage.removeItem("showFavouritesIndicator");
                    state.showFavouritesIndicator = false;
                }
            }
            localStorage.setItem(location, JSON.stringify(filterredProducts));
            needSyncWithDB &&
                fetchApi({
                    method: "POST",
                    url: `/${location === "cart" ? "cart" : "wishlist"}/remove`,
                    body: { productIds: removedProducts },
                });
        },
        removeProductsFromLocalCartOrFavourites(state, action) {
            if (action.payload.includes("cart")) {
                localStorage.removeItem("cart");
                localStorage.removeItem("reserveOfAvailable");
                state.productsInCart = [];
            }
            if (action.payload.includes("favourites")) {
                localStorage.removeItem("favourites");
                localStorage.removeItem("showFavouritesIndicator");
                state.productsInFavourites = [];
                state.showFavouritesIndicator = false;
            }
        },
        rewriteCartOrFavourites(state, action) {
            const { location, needSyncWithDB, newProducts } = action.payload;
            if (location === "cart") {
                state.productsInCart = newProducts;
            }
            else {
                state.productsInFavourites = newProducts;
                if (newProducts.length === 0) {
                    localStorage.removeItem("showFavouritesIndicator");
                    state.showFavouritesIndicator = false;
                }
            }
            localStorage.setItem(location, JSON.stringify(newProducts));
            needSyncWithDB &&
                fetchApi({
                    method: "POST",
                    url: `/${location === "cart" ? "cart" : "wishlist"}/save`,
                    body: {
                        items: newProducts,
                        saveHard: true,
                    },
                });
        },
        changeStatusOfFavouritesIndicator(state, action) {
            action.payload
                ? localStorage.setItem("showFavouritesIndicator", "true")
                : localStorage.removeItem("showFavouritesIndicator");
            state.showFavouritesIndicator = action.payload;
        },
    },
});
export const { addProductToCartOrFavourites, changeStatusOfFavouritesIndicator, removeProductFromCartOrFavourites, removeProductsFromLocalCartOrFavourites, rewriteCartOrFavourites, } = cartSlice.actions;
export default cartSlice.reducer;
