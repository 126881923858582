import "./PhoneInput.scss";
import React, { useEffect, useState } from "react";
export const PhoneInput = ({ autoFocus, className, disabled, id, required, value, withMask, onChange, onFocus, onKeyDown, setValue, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const [formatedPhoneNumber, setFormattedPhoneNumber] = useState("");
    useEffect(() => {
        setFormattedPhoneNumber(formatNumber(value || ""));
    }, [value]);
    const formatNumber = (value) => {
        var _a, _b, _c;
        if (value) {
            return [
                value.substring(0, 3) || "",
                (((_a = value.substring(0, 3)) === null || _a === void 0 ? void 0 : _a.length) === 3 ? " " : "") + value.substring(3, 6) || "",
                (((_b = value.substring(3, 6)) === null || _b === void 0 ? void 0 : _b.length) === 3 ? "-" : "") + value.substring(6, 8) || "",
                (((_c = value.substring(6, 8)) === null || _c === void 0 ? void 0 : _c.length) === 2 ? "-" : "") + value.substring(8, 10) || "",
            ]
                .join("")
                .trim();
        }
        return "";
    };
    const onChangeHandler = (e) => {
        const { value } = e.target;
        if (value.match(/[A-Za-zА-Яа-яЁё]/i)) {
            e.currentTarget.value = value.substring(0, value.length - 1) + "";
            return;
        }
        let newValue = value.replace(/[^0-9]/g, "");
        if (newValue.startsWith("+7 (")) {
            newValue = newValue.slice(4);
        }
        else if (newValue.startsWith("+7(") || newValue.startsWith("+7 ") || newValue.startsWith("8 (")) {
            newValue = newValue.slice(3);
        }
        else if (newValue.startsWith("7 ") ||
            newValue.startsWith("8 ") ||
            newValue.startsWith("7(") ||
            newValue.startsWith("8(") ||
            newValue.startsWith("+7")) {
            newValue = newValue.slice(2);
        }
        else if (newValue.startsWith("79") || newValue.startsWith("89")) {
            newValue = newValue.slice(1);
        }
        if (newValue.length >= 11) {
            newValue = newValue.substring(0, newValue.length - 1);
        }
        const reg = /^[0-9]*?$/;
        if (!reg.test(newValue)) {
            e.currentTarget.value = value.substring(0, value.length - 1) + "";
        }
        setFormattedPhoneNumber(formatNumber(newValue));
        if (newValue !== "") {
            setValue && setValue(newValue);
            onChange && onChange(e);
        }
        else {
            setValue && setValue("");
        }
    };
    return (React.createElement("div", { className: 'phoneInput' },
        React.createElement("input", { className: className || "", id: id, type: 'phone', placeholder: '___ ___ __ __', value: formatedPhoneNumber, required: required, disabled: disabled, autoFocus: autoFocus, onChange: onChangeHandler, onFocus: onFocus, onKeyDown: (e) => {
                if (e.currentTarget.value.slice(-1) === "-") {
                    e.currentTarget.value = e.currentTarget.value.slice(0, -1);
                }
                onKeyDown && onKeyDown(e);
            } }),
        withMask && (React.createElement("p", null,
            !!((_a = (value || "").substring(0, 1)) === null || _a === void 0 ? void 0 : _a.length) && React.createElement("span", { className: 'phoneInput__mask__item span_1' }),
            !!((_b = (value || "").substring(1, 2)) === null || _b === void 0 ? void 0 : _b.length) && React.createElement("span", { className: 'phoneInput__mask__item span_2' }),
            !!((_c = (value || "").substring(2, 3)) === null || _c === void 0 ? void 0 : _c.length) && React.createElement("span", { className: 'phoneInput__mask__item span_3' }),
            !!((_d = (value || "").substring(3, 4)) === null || _d === void 0 ? void 0 : _d.length) && React.createElement("span", { className: 'phoneInput__mask__item span_4' }),
            !!((_e = (value || "").substring(4, 5)) === null || _e === void 0 ? void 0 : _e.length) && React.createElement("span", { className: 'phoneInput__mask__item span_5' }),
            !!((_f = (value || "").substring(5, 6)) === null || _f === void 0 ? void 0 : _f.length) && React.createElement("span", { className: 'phoneInput__mask__item span_6' }),
            !!((_g = (value || "").substring(6, 7)) === null || _g === void 0 ? void 0 : _g.length) && React.createElement("span", { className: 'phoneInput__mask__item span_7' }),
            !!((_h = (value || "").substring(7, 8)) === null || _h === void 0 ? void 0 : _h.length) && React.createElement("span", { className: 'phoneInput__mask__item span_8' }),
            !!((_j = (value || "").substring(8, 9)) === null || _j === void 0 ? void 0 : _j.length) && React.createElement("span", { className: 'phoneInput__mask__item span_9' }),
            !!((_k = (value || "").substring(9, 10)) === null || _k === void 0 ? void 0 : _k.length) && React.createElement("span", { className: 'phoneInput__mask__item span_10' })))));
};
