import "./Snackbar.scss";
import React, { useEffect } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeSnackbarData } from "Redux/slices/appSlice";
import { SuccessIcon_monochrome } from "Svg/SuccessIcon_monochrome";
import { AttentionIcon_color } from "Svg/AttentionIcon_color";
export const Snackbar = ({ className }) => {
    const dispatch = useAppDispatch();
    const { snackbarData } = useAppSelector((state) => state.app);
    useEffect(() => {
        let timer;
        if (snackbarData) {
            timer = setTimeout(() => {
                dispatch(changeSnackbarData(null));
            }, 4000);
        }
        return () => {
            timer && clearTimeout(timer);
        };
    }, [snackbarData]);
    return (React.createElement("div", { className: `snackbarNotification` +
            (snackbarData ? ` snackbarNotification_visible` : "") +
            (className ? ` ${className}` : "") },
        React.createElement("div", { className: 'snackbarNotification__icon' }, (snackbarData === null || snackbarData === void 0 ? void 0 : snackbarData.status) === "success" ? (React.createElement(SuccessIcon_monochrome, null)) : (React.createElement(AttentionIcon_color, { backgroundFill: 'var(--red-500)' }))),
        React.createElement("span", { className: 'snackbarNotification__msg typography_tec_buttonM' }, snackbarData === null || snackbarData === void 0 ? void 0 : snackbarData.message)));
};
