import "./DesktopCatalogFiltersSkeleton.scss";
import React from "react";
import { SkeletonsList } from "Components/SkeletonsList/SkeletonsList";
export const DesktopCatalogFiltersSkeleton = () => {
    return (React.createElement("div", { className: 'desktopFiltersSkeleton' },
        React.createElement("div", { className: 'desktopFiltersSkeleton' },
            React.createElement("div", { className: 'desktopFiltersSkeleton__item' },
                React.createElement("div", { className: 'desktopFiltersSkeleton__item__title skeletonLoading' }),
                React.createElement("div", { className: 'desktopFiltersSkeleton__item__list' },
                    React.createElement(SkeletonsList, { quantity: 12 },
                        React.createElement("div", { className: 'desktopFiltersSkeleton__item__list__filter' },
                            React.createElement("div", { className: 'desktopFiltersSkeleton__item__list__filter__checkbox skeletonLoading' }),
                            React.createElement("div", { className: 'desktopFiltersSkeleton__item__list__filter__text skeletonLoading' }))))),
            React.createElement(SkeletonsList, { quantity: 2 },
                React.createElement("div", { className: 'desktopFiltersSkeleton__item' },
                    React.createElement("div", { className: 'desktopFiltersSkeleton__item__title skeletonLoading' }))))));
};
