import "./CatalogSkeleton.scss";
import React from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { DesktopCatalogFiltersSkeleton } from "../DesktopCatalogFiltersSkeleton/DesktopCatalogFiltersSkeleton";
import { CatalogProductCardSkeleton } from "../CatalogProductCardSkeleton/CatalogProductCardSkeleton";
import { SkeletonsList } from "Components/SkeletonsList/SkeletonsList";
export const CatalogSkeleton = () => {
    const location = useLocation();
    const { breakpointMatches } = useAppSelector((state) => state.app);
    return (React.createElement("div", { className: 'catalogSkeleton' },
        React.createElement("div", { className: 'catalogSkeleton__breadcrumbs' },
            React.createElement("div", { className: 'catalogSkeleton__breadcrumbs__item skeletonLoading' }),
            React.createElement("span", { className: 'catalogSkeleton__breadcrumbs__devider' }, "\u00A0\u00A0/\u00A0\u00A0"),
            React.createElement("div", { className: 'catalogSkeleton__breadcrumbs__item skeletonLoading' })),
        React.createElement("div", { className: 'catalogSkeleton__title skeletonLoading' }),
        React.createElement("div", { className: 'catalogSkeleton__mobileFiltersBtn skeletonLoading' }),
        React.createElement("div", { className: 'catalogSkeleton__controlsPanel' },
            React.createElement("div", { className: 'catalogSkeleton__controlsPanel__item skeletonLoading' }),
            React.createElement("div", { className: 'catalogSkeleton__controlsPanel__item skeletonLoading' }),
            React.createElement("div", { className: 'catalogSkeleton__controlsPanel__item skeletonLoading' })),
        React.createElement("div", { className: 'catalogSkeleton__mainContent' },
            React.createElement("div", { className: 'catalogSkeleton__mainContent__filters' },
                React.createElement(DesktopCatalogFiltersSkeleton, null)),
            React.createElement("div", { className: 'catalogSkeleton__mainContent__products' },
                location.pathname !== "/categories/vygoda/" &&
                    location.pathname !== "/specials/garantiya-luchshey-ceny/" && (React.createElement("div", { className: 'catalogSkeleton__mainContent__products__saleBanner skeletonLoading', style: { aspectRatio: breakpointMatches[600] ? "25 / 9" : "64 / 7" } })),
                React.createElement("div", { className: 'catalogSkeleton__mainContent__products__grid' },
                    React.createElement(SkeletonsList, { quantity: 35, children: React.createElement(CatalogProductCardSkeleton, null) }))))));
};
