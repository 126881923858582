import "./Footer.scss";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { changeFooterIntersectionStatus } from "Redux/slices/appSlice";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { cdnHost, hostName } from "Static/variables";
import { ChevronIcon_16 } from "Svg/ChevronIcon_16";
import { CustomCollapse } from "Components/CustomCollapse/CustomCollapse";
import { StoreContactInfo } from "Components/StoreContactInfo/StoreContactInfo";
import { MobileAppQr } from "./components/MobileAppQr/MobileAppQr";
import { NewsSubscription } from "./components/NewsSubscription/NewsSubscription";
const titles = ["LS.NET.RU", "Покупателю"];
const luxuryLinks = [
    { name: "Мужчинам", path: "/men/" },
    { name: "Пункты самовывоза", path: "/pickupPoints/" },
    { name: "Женщинам", path: "/women/" },
    { name: "Доставка и оплата", path: "/sections/shipping/" },
    { name: "Статьи", path: "/articles/" },
    { name: "Возврат товара", path: "/sections/returns/" },
    { name: "Идеи", path: "/feed/" },
    { name: "Отзывы клиентов", path: "http://market.yandex.ru/shop/105646/reviews?clid=703" },
    { name: "Вопрос - ответ", path: "/faq/" },
    { name: "Карта сайта", path: "/map/" },
    { name: "Регистрация", path: "/reg/" },
    { name: "О нас", path: "/service/about/" },
    { name: "Программа лояльности", path: "/loyaltyProgram/" },
    { name: "Селлерам", path: `https://sellers.${hostName}/register.html` },
];
export const Footer = () => {
    const dispatch = useAppDispatch();
    const { city, footerLinks, showFooter } = useAppSelector((state) => state.app);
    const { user } = useAppSelector((state) => state.userInfo);
    const getText = (linksArray, className) => {
        return linksArray.map((link) => {
            if (user && link.name === "Регистрация") {
                return null;
            }
            else if (link.path.startsWith("http")) {
                return (React.createElement("a", { className: className, href: link.path, key: link.name }, link.name));
            }
            else {
                return (React.createElement(NavLink, { className: className, to: link.path, key: link.name, style: { color: link.path === "/loyaltyProgram/" ? "var(--blue-500)" : "" } }, link.name));
            }
        });
    };
    const [seoLinks, setSeoLinks] = useState(footerLinks);
    useEffect(() => {
        setSeoLinks(footerLinks);
    }, [footerLinks]);
    useEffect(() => {
        const footerObserver = new IntersectionObserver(footerObserverHandler);
        const footer = document.querySelector(".siteFooter");
        footer && footerObserver.observe(footer);
        return () => {
            footer && footerObserver.unobserve(footer);
        };
    }, []);
    const footerObserverHandler = (entries) => {
        dispatch(changeFooterIntersectionStatus(entries[0].isIntersecting));
    };
    const [email, setEmail] = useState("");
    const [checkedGenders, setCheckedGenders] = useState(["1", "2"]);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const onChangeEmail = (value) => setEmail(value);
    const onChangeGenders = (status, gender) => setCheckedGenders((prev) => (status ? [...prev, gender] : prev.filter((el) => el !== gender)));
    const onChangeSubscribeState = (state) => setIsSubscribed(state);
    const subsProps = { email, onChangeEmail, checkedGenders, onChangeGenders, isSubscribed, onChangeSubscribeState };
    return showFooter ? (React.createElement("footer", { className: 'siteFooter' },
        React.createElement("div", { className: 'siteFooter__desctop' },
            React.createElement("div", { className: 'siteFooter__desctop__contacts' },
                React.createElement("div", { className: 'siteFooter__desctop__contacts__item siteFooter__desctop__contacts__item_questions' },
                    React.createElement("span", { className: 'typography_h2' }, "\u041E\u0442\u0432\u0435\u0442\u0438\u043C \u043D\u0430 \u0432\u0430\u0448\u0438 \u0432\u043E\u043F\u0440\u043E\u0441\u044B")),
                React.createElement("div", { className: 'siteFooter__desctop__contacts__item' },
                    React.createElement(StoreContactInfo, null)),
                React.createElement("div", { className: 'siteFooter__desctop__contacts__item' },
                    React.createElement(MobileAppQr, null))),
            React.createElement("div", { className: 'siteFooter__desctop__links' },
                React.createElement("div", { className: 'siteFooter__desctop__links__item' },
                    React.createElement("span", { className: 'typography_tec_15_menu' }, titles[0]),
                    React.createElement("div", { className: 'siteFooter__desctop__links__item__list siteFooter__desctop__links__item__list_reordered' },
                        React.createElement(NavLink, { className: 'typography_tec_16_default', to: `/city/${city === null || city === void 0 ? void 0 : city.city_name}/` }, city === null || city === void 0 ? void 0 : city.city_name),
                        getText(luxuryLinks, "typography_tec_16_default"))),
                React.createElement("div", { className: 'siteFooter__desctop__links__devider' }),
                React.createElement("div", { className: 'siteFooter__desctop__links__item siteFooter__desctop__links__item_subscription' },
                    React.createElement("span", { className: 'typography_tec_15_menu' }, user && !user.stop_email ? "Подписка на новости" : "Скидка 5% за подписку на новости"),
                    React.createElement("div", { className: 'siteFooter__desctop__links__item__list siteFooter__desctop__links__item__list_social' },
                        React.createElement(NewsSubscription, { ...subsProps }))),
                React.createElement("div", { className: 'siteFooter__desctop__links__devider' }),
                React.createElement("div", { className: 'siteFooter__desctop__links__item' },
                    React.createElement("span", { className: 'typography_tec_15_menu' }, titles[1]),
                    React.createElement("div", { className: 'siteFooter__desctop__links__item__list siteFooter__desctop__links__item__list_brands' }, seoLinks.map((link) => (React.createElement("a", { className: 'typography_tec_16_default', key: link.name, href: link.url }, link.name)))))),
            React.createElement("div", { className: 'siteFooter__desctop__policy' },
                React.createElement("p", { className: 'typography_tec_13_medium siteFooter__desctop__policy__address' },
                    React.createElement("span", { className: 'siteFooter__desctop__policy__address__firstPart' },
                        "\u00A9 \u00AB",
                        React.createElement("span", null, "LS.NET.RU"),
                        "\u00BB, 2011\u2014 ",
                        new Date().getFullYear(),
                        ",",
                        " "),
                    React.createElement("span", { className: 'siteFooter__desctop__policy__address__secondPart' },
                        "\u041E\u041E\u041E \u00AB\u0421\u0422\u0420\u0410\u0422\u0415\u0413\u00BB, \u0420\u0424, ",
                        React.createElement("span", null, "123112"),
                        ",",
                        React.createElement("span", null, " \u0433. \u041C\u043E\u0441\u043A\u0432\u0430"),
                        ",",
                        React.createElement("span", null,
                            "\u00A0",
                            "\u0432\u043D.\u0442\u0435\u0440.\u0433. \u041C\u0443\u043D\u0438\u0446\u0438\u043F\u0430\u043B\u044C\u043D\u044B\u0439 \u043E\u043A\u0440\u0443\u0433 \u041F\u0440\u0435\u0441\u043D\u0435\u043D\u0441\u043A\u0438\u0439, \u043D\u0430\u0431.\u041F\u0440\u0435\u0441\u043D\u0435\u043D\u0441\u043A\u0430\u044F, \u0434. 12, \u043F\u043E\u043C\u0435\u0449. 10/45, \u043E\u0444\u0438\u0441 385"))),
                React.createElement(NavLink, { className: 'typography_tec_13_medium', to: '/service/consentToProcessing/' }, "\u0421\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445"))),
        React.createElement("div", { className: 'siteFooter__mobile' },
            React.createElement("div", { className: 'siteFooter__mobile__main' },
                React.createElement("div", { className: 'siteFooter__mobile__main__contacts' },
                    React.createElement("span", { className: 'typography_h2' }, "\u041E\u0442\u0432\u0435\u0442\u0438\u043C \u043D\u0430 \u0432\u0430\u0448\u0438 \u0432\u043E\u043F\u0440\u043E\u0441\u044B"),
                    React.createElement(StoreContactInfo, null)),
                React.createElement("div", { className: 'siteFooter__mobile__main__subscription' },
                    React.createElement("span", { className: 'typography_h2' }, "\u0421\u043A\u0438\u0434\u043A\u0430 5% \u0437\u0430 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u043D\u0430 \u043D\u043E\u0432\u043E\u0441\u0442\u0438"),
                    React.createElement(NewsSubscription, { ...subsProps }))),
            React.createElement("div", { className: 'siteFooter__mobile__links' },
                React.createElement(CustomCollapse, { className: 'siteFooter__mobile__links__collapse', expandIconPosition: 'end', expandIcon: ({ isActive }) => {
                        return (React.createElement(ChevronIcon_16, { className: 'siteFooter__mobile__links__collapse__expandIcon', style: { transform: `rotate(${isActive ? "270deg" : "90deg"})` } }));
                    } },
                    React.createElement(CustomCollapse.Panel, { className: 'siteFooter__mobile__links__collapse__panel', panelKey: '1', header: React.createElement("span", { className: 'typography_tec_16_default' }, titles[0]) },
                        React.createElement("div", { className: 'siteFooter__mobile__links__collapse__panel__content siteFooter__desctop__links__item__list_reordered' },
                            React.createElement(NavLink, { className: 'typography_tec_13_medium', to: `/city/${city === null || city === void 0 ? void 0 : city.city_name}/` }, city === null || city === void 0 ? void 0 : city.city_name),
                            getText(luxuryLinks, "typography_tec_13_medium"))),
                    React.createElement(CustomCollapse.Panel, { className: 'siteFooter__mobile__links__collapse__panel', panelKey: '3', header: React.createElement("span", { className: 'typography_tec_16_default' }, titles[1]) },
                        React.createElement("div", { className: 'siteFooter__mobile__links__collapse__panel__content ' }, seoLinks.map((link) => (React.createElement("a", { className: 'typography_tec_13_medium', key: link.name, href: link.url }, link.name))))))),
            React.createElement("div", { className: 'siteFooter__mobile__policy' },
                React.createElement("div", { className: 'siteFooter__mobile__policy__info' },
                    React.createElement("p", { className: 'typography_tec_13_medium' },
                        "\u00A9 \u00AB",
                        React.createElement("span", null, "LS.NET.RU"),
                        "\u00BB, 2011\u2014 ",
                        new Date().getFullYear(),
                        ",",
                        " ",
                        React.createElement("span", null,
                            "\u041E\u041E\u041E \u00AB\u0421\u0422\u0420\u0410\u0422\u0415\u0413\u00BB, \u0420\u0424,",
                            React.createElement("span", null, "123112"),
                            ",",
                            React.createElement("span", null, " \u0433. \u041C\u043E\u0441\u043A\u0432\u0430"),
                            ",",
                            React.createElement("span", null,
                                "\u00A0",
                                "\u0432\u043D.\u0442\u0435\u0440.\u0433. \u041C\u0443\u043D\u0438\u0446\u0438\u043F\u0430\u043B\u044C\u043D\u044B\u0439 \u043E\u043A\u0440\u0443\u0433 \u041F\u0440\u0435\u0441\u043D\u0435\u043D\u0441\u043A\u0438\u0439, \u043D\u0430\u0431.\u041F\u0440\u0435\u0441\u043D\u0435\u043D\u0441\u043A\u0430\u044F, \u0434. 12, \u043F\u043E\u043C\u0435\u0449. 10/45, \u043E\u0444\u0438\u0441 385"))),
                    React.createElement(NavLink, { className: 'typography_tec_13_medium', to: '/service/consentToProcessing/' }, "\u0421\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445")),
                React.createElement("div", { className: 'siteFooter__mobile__policy__mobileApps' },
                    React.createElement("a", { href: 'https://itunes.apple.com/us/app/internet-magazin-brendovoj/id913481541?ls=1&mt=8', target: '_blank', rel: 'noopener noreferrer' },
                        React.createElement("picture", null,
                            React.createElement("source", { srcSet: `${cdnHost}/images/app-store.webp`, type: 'image/webp' }),
                            React.createElement("img", { src: `${cdnHost}/images/app-store.png`, alt: 'app store link', loading: 'lazy' }))),
                    React.createElement("a", { href: 'https://play.google.com/store/apps/details?id=com.lsboutqiue.app', target: '_blank', rel: 'noopener noreferrer' },
                        React.createElement("picture", null,
                            React.createElement("source", { srcSet: `${cdnHost}/images/google-play.webp`, type: 'image/webp' }),
                            React.createElement("img", { src: `${cdnHost}/images/google-play.png`, alt: 'google play link', loading: 'lazy' })))))))) : null;
};
