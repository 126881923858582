import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isUserTypeDefined: false,
    token: "",
    user: null,
};
export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        saveUserData(state, action) {
            state.user = action.payload;
        },
        saveAuthToken(state, action) {
            !action.payload ? localStorage.removeItem("token") : localStorage.setItem("token", action.payload);
            state.token = action.payload;
        },
        changeUserTypeDefined(state, action) {
            state.isUserTypeDefined = action.payload;
        },
    },
});
export const { saveUserData, saveAuthToken, changeUserTypeDefined } = userInfoSlice.actions;
export default userInfoSlice.reducer;
