import "./CustomDrawer.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { isBrowser } from "Utils/isBrowser";
export const CustomDrawer = ({ children, className, getContainer, contentWrapperStyle, destroyOnClose = false, footer, headerStyle, mask = true, onClose, open, placement = "right", rootClassName, rootStyle, title, contentWrapperClassName, }) => {
    const [hasTransitionedIn, setHasTransitionedIn] = useState(false);
    const drawerBodyRef = useRef(null);
    useEffect(() => {
        var _a, _b;
        if (open && !hasTransitionedIn) {
            setHasTransitionedIn(true);
            if (!getContainer) {
                document.body.style.width = `calc(100% - ${innerWidth - document.body.clientWidth}px)`;
                document.body.style.overflowY = "hidden";
            }
            const isHaveFocus = (_a = drawerBodyRef.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement);
            !isHaveFocus && ((_b = drawerBodyRef.current) === null || _b === void 0 ? void 0 : _b.focus());
        }
        else if (!open && hasTransitionedIn) {
            setTimeout(() => setHasTransitionedIn(false), 300);
        }
    }, [open, hasTransitionedIn]);
    useEffect(() => {
        return () => {
            if (document) {
                const modalsCount = document.body.querySelectorAll(".customModal").length;
                const drawersCount = document.body.querySelectorAll(".customDrawer").length;
                if (!modalsCount && !drawersCount && !getContainer && hasTransitionedIn) {
                    document.body.style.overflowY = "";
                    document.body.style.width = "";
                }
            }
        };
    }, [hasTransitionedIn]);
    const escapeHandler = useCallback((e) => {
        const isCurrentDrawer = e.currentTarget.contains(document.activeElement);
        e.code === "Escape" && isCurrentDrawer && onClose && onClose();
    }, [onClose]);
    const DrawerJsx = (React.createElement("div", { className: `customDrawer customDrawer_${placement}` + (rootClassName ? ` ${rootClassName}` : ""), tabIndex: -1, onKeyDown: escapeHandler, style: rootStyle },
        (hasTransitionedIn || open) && mask && (React.createElement("div", { onClick: onClose, className: `customDrawer__mask` +
                (hasTransitionedIn ? " customDrawer__mask_in" : "") +
                (open ? " customDrawer__mask_visible" : "") })),
        (hasTransitionedIn || open) && (React.createElement("div", { className: `customDrawer__content-wrapper customDrawer__content-wrapper-${placement}` +
                (hasTransitionedIn ? " customDrawer__content-wrapper_in" : "") +
                (open ? " customDrawer__content-wrapper_visible" : "") +
                (contentWrapperClassName ? ` ${contentWrapperClassName}` : ""), style: contentWrapperStyle },
            React.createElement("div", { className: `customDrawer__content` + (className ? ` ${className}` : ""), "aria-modal": true, "aria-label": 'Drawer content', role: 'dialog' },
                React.createElement("div", { className: 'customDrawer__content__main' },
                    title && (React.createElement("div", { className: 'customDrawer__content__main__header', style: headerStyle },
                        React.createElement("div", { className: 'customDrawer__content__main__header__title-wrapper' },
                            React.createElement("div", { className: 'customDrawer__content__main__header__title' }, title)))),
                    React.createElement("div", { className: 'customDrawer__content__main__body', ref: drawerBodyRef, tabIndex: 0 }, children),
                    footer && React.createElement("div", { className: 'customDrawer__content__main__footer' }, footer)))))));
    if (!hasTransitionedIn && !open && destroyOnClose)
        return null;
    return isBrowser() ? createPortal(DrawerJsx, (getContainer && getContainer()) || document.body) : DrawerJsx;
};
