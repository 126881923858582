import "./CustomCollapse.scss";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
const CollapseContext = createContext(null);
const useCollapseContext = () => {
    const currentContext = useContext(CollapseContext);
    if (!currentContext)
        throw new Error("No context found for Collapse");
    return currentContext;
};
export const CustomCollapse = ({ accordion = false, activeKeys = undefined, children, className, expandIconPosition = "end", expandIcon = () => undefined, }) => {
    const [activePanels, setActivePanels] = useState(activeKeys);
    const toggleActivePanels = (key) => setActivePanels(key);
    useEffect(() => {
        setActivePanels(activeKeys);
    }, [activeKeys]);
    return (React.createElement(CollapseContext.Provider, { value: { expandIconFn: expandIcon, expandIconPosition, activeKeys: activePanels, accordion, toggleActivePanels } },
        React.createElement("div", { className: "customCollapse" + (className ? ` ${className}` : "") }, children)));
};
const Panel = ({ children, className, collapsible = true, forceRender = false, header, onChange, panelKey, }) => {
    const { activeKeys, accordion, expandIconFn, expandIconPosition, toggleActivePanels } = useCollapseContext();
    const [isFirstRender, setIsFirstRender] = useState(!forceRender);
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        !isFirstRender && onChange && onChange(isActive);
    }, [isActive, isFirstRender]);
    const headerRef = useRef(null);
    const contentWrapperRef = useRef(null);
    useEffect(() => {
        const isIdMatched = Array.isArray(activeKeys) ? !!activeKeys.find((el) => el == panelKey) : activeKeys == panelKey;
        if (isIdMatched && isFirstRender) {
            setIsFirstRender(false);
        }
        else if (isIdMatched) {
            openHandler();
        }
        else if (!isIdMatched && !isFirstRender) {
            closeHandler();
        }
    }, [activeKeys]);
    useEffect(() => {
        !isFirstRender && !forceRender && openHandler();
    }, [isFirstRender]);
    const expandIcon = useMemo(() => {
        return collapsible ? expandIconFn({ isActive, header, panelKey, collapsible, className }) : null;
    }, [isActive, collapsible]);
    const collapseHandler = (e) => {
        if (headerRef.current.contains(e.target)) {
            if (!collapsible || (activeKeys && activeKeys.toString().length && !accordion))
                return;
            if (isFirstRender) {
                setIsFirstRender(false);
                return;
            }
            isActive ? closeHandler() : openHandler();
        }
    };
    const closeHandler = () => {
        accordion && activeKeys === panelKey && toggleActivePanels("");
        setIsActive(false);
    };
    const openHandler = () => {
        accordion && toggleActivePanels(panelKey);
        setIsActive(true);
    };
    return (React.createElement("div", { className: "customCollapse__panel" + (className ? ` ${className}` : "") },
        React.createElement("div", { className: 'customCollapse__panel__header', ref: headerRef, onClick: collapseHandler },
            React.createElement("div", { className: 'customCollapse__panel__header__text' }, header),
            expandIcon && (React.createElement("div", { className: `customCollapse__panel__header__icon customCollapse__panel__header__icon_${expandIconPosition}` }, expandIcon))),
        !isFirstRender ? (React.createElement("div", { className: "customCollapse__panel__contentWrapper" + (isActive ? " customCollapse__panel__contentWrapper_active" : ""), ref: contentWrapperRef },
            React.createElement("div", { className: 'customCollapse__panel__content' }, children))) : null));
};
CustomCollapse.Panel = Panel;
