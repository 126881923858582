import { Loader } from "Components/Loader/Loader";
import "./SubmitBtn.scss";
import React from "react";
import { useAppSelector } from "Hooks/useAppSelector";
export const SubmitBtn = ({ className, controlLocation, disabled, isLoading, loadingText, normalSizeBP = 600, onClick, text, }) => {
    const { breakpointMatches } = useAppSelector((state) => state.app);
    return (React.createElement("button", { className: `submitBtn customBtn customBtn_contained` +
            (isLoading ? " submitBtn_withLoader" : "") +
            (controlLocation === "modal" ? " submitBtn_modalMargin" : "") +
            (breakpointMatches[normalSizeBP] || controlLocation === "drawer" ? "" : " customBtn_large") +
            (className ? ` ${className}` : ""), disabled: disabled, onClick: !isLoading && onClick ? onClick : undefined },
        React.createElement("span", { className: 'submitBtn__text' },
            isLoading && loadingText ? loadingText : text,
            isLoading && React.createElement(Loader, { className: 'submitBtn__text__loader loader__wrapper_disabled' }))));
};
