import { saveCookie } from "Utils/saveCookie";
import localStorage from "Utils/localStorage";
import { hostName } from "Static/variables";
export const saveUtmLabels = (user, labels) => {
    saveCookie("utmLabels", labels.utmLabels, { domain: `.${hostName}`, "max-age": 86400 });
    if (!user) {
        const antecedentLabels = localStorage.getItem("utmLabels");
        const actualLabels = antecedentLabels ? JSON.parse(antecedentLabels) : [];
        actualLabels.push(labels);
        localStorage.setItem("utmLabels", JSON.stringify(actualLabels));
    }
};
