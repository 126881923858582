import "./CustomInput.scss";
import React from "react";
import { PhoneInput } from "Components/PhoneInput/PhoneInput";
export const CustomInput = ({ autoComplete, autoFocus, className, defaultValue, disabled, id, inputValue, integerOnly, label, max, message, min, onBlurHandler, onChangeHandler, onFocusHandler, onKeyDownHandler, placeholder, prefix, required, setInputValue, status, style, suffix, type = "text", withMask, }) => {
    return (React.createElement("div", { className: "customInput customInput_wrapper" +
            (className ? ` ${className}` : "") +
            (status ? ` customInput_${status}` : "") +
            (disabled ? " customInput_disabled" : ""), style: style },
        label && (React.createElement("label", { className: 'typography_tec_13_medium customInput__label', htmlFor: id }, label)),
        React.createElement("div", { className: 'customInput__main', style: { padding: type === "date" ? "0 14px" : "0" } },
            prefix && React.createElement("div", { className: 'customInput__main__prefix' }, prefix),
            type === "phone" ? (React.createElement(PhoneInput, { className: "typography_tec_16_default" + (suffix ? "" : " customInput__main__phoneInputWithPadding"), id: id, value: inputValue, required: required, withMask: withMask, disabled: disabled, autoFocus: autoFocus, setValue: setInputValue, onChange: onChangeHandler, onKeyDown: onKeyDownHandler, onFocus: onFocusHandler })) : (React.createElement("input", { className: "typography_tec_16_default customInput__main__input" + (type === "number" ? " withoutArrows" : ""), autoComplete: autoComplete, autoFocus: autoFocus, style: {
                    paddingLeft: prefix || type === "date" ? "" : "16px",
                    paddingRight: suffix || type === "date" ? "" : "16px",
                }, defaultValue: defaultValue, disabled: disabled, id: id, max: max, min: min, placeholder: placeholder, required: required, type: type, value: inputValue, onChange: (e) => {
                    onChangeHandler && onChangeHandler(e);
                    setInputValue && setInputValue(e.target.value);
                }, onKeyDown: (e) => {
                    if (integerOnly &&
                        isNaN(+e.key) &&
                        e.key !== "0" &&
                        e.key !== "Backspace" &&
                        e.key !== "Enter" &&
                        e.key !== "Tab" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight" &&
                        !e.ctrlKey) {
                        e.preventDefault();
                        return;
                    }
                    onKeyDownHandler && onKeyDownHandler(e);
                }, onFocus: (e) => onFocusHandler && onFocusHandler(e), onBlur: (e) => onBlurHandler && onBlurHandler(e) })),
            suffix && React.createElement("div", { className: 'customInput__main__suffix' }, suffix),
            disabled && React.createElement("div", { className: 'customInput__disableMask' })),
        message && (React.createElement("span", { className: "typography_tec_13_medium customInput__message" + (status === "error" ? " customInput__message_error" : "") }, message))));
};
