export const getBrowserName = () => {
    var _a;
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Edge") > -1 ||
        userAgent.indexOf("Edg") > -1 ||
        userAgent.indexOf("EdgA") > -1 ||
        userAgent.indexOf("EdgiOS") > -1) {
        return "Edge";
    }
    else if (userAgent.indexOf("Opera") != -1 || userAgent.indexOf("OPR") != -1) {
        return "Opera";
    }
    else if ((userAgent.indexOf("Safari") != -1 || userAgent.indexOf("iOS") != -1) &&
        userAgent.indexOf("Chrome") === -1 &&
        userAgent.indexOf("CriOS") === -1) {
        return "Safari";
    }
    else if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("FxiOS") != -1) {
        return "Firefox";
    }
    else if ((userAgent.indexOf("MSIE") != -1 || userAgent.indexOf("Windows NT") != -1) &&
        userAgent.indexOf("Chrome") === -1) {
        return "IE";
    }
    else if (userAgent.indexOf("Yowser") != -1 || userAgent.indexOf("YaBrowser") != -1) {
        return "Yandex";
    }
    else if ((userAgent.indexOf("Chrome") != -1 || userAgent.indexOf("CriOS") != -1) &&
        ((_a = navigator.vendor) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf("google")) > -1) {
        return "Chrome";
    }
    else {
        return "Other";
    }
};
