import "./CustomModal.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
export const CustomModal = ({ children, className, defaultPaddings = true, footer, fullscreen = true, header, maskClosable = true, onClose, onRendered, open, }) => {
    const modalBodyRef = useRef(null);
    const modalWrapperRef = useRef(null);
    const modalMaskRef = useRef(null);
    const triggerCoordinates = useRef({ x: 0, y: 0 });
    const scrollWidth = useRef(0);
    const isFirstLoad = useRef(true);
    const isMouseDownInModal = useRef(false);
    const [isShowed, setIsShowed] = useState(false);
    useEffect(() => {
        if (open) {
            if (isFirstLoad.current)
                isFirstLoad.current = false;
            const saveCoord = (e) => {
                triggerCoordinates.current = { x: e.clientX, y: e.clientY };
                document.removeEventListener("click", saveCoord);
            };
            document.addEventListener("click", saveCoord);
            setIsShowed(true);
        }
        else {
            if (!isFirstLoad.current) {
                const modalBody = modalBodyRef.current;
                const modalMask = modalMaskRef.current;
                modalBody.classList.add("customModal__body_closed");
                modalBody.classList.remove("customModal__body_visible");
                modalMask.classList.remove("customModal__mask_blackout");
                setTimeout(() => {
                    setIsShowed(false);
                    triggerCoordinates.current = { x: 0, y: 0 };
                }, 250);
            }
        }
    }, [open]);
    useEffect(() => {
        var _a, _b;
        if (isShowed) {
            if (!scrollWidth.current)
                scrollWidth.current = innerWidth - document.body.clientWidth;
            document.body.style.width = `calc(100% - ${scrollWidth.current}px)`;
            document.body.style.overflowY = "hidden";
            const modalBody = modalBodyRef.current;
            const modalMask = modalMaskRef.current;
            const { x: triggerX, y: triggerY } = triggerCoordinates.current;
            if (triggerX || triggerY) {
                const transformX = triggerX - (document.documentElement.clientWidth - modalBody.offsetWidth) / 2;
                const transformY = document.documentElement.clientHeight > modalBody.offsetHeight
                    ? triggerY - (document.documentElement.clientHeight - modalBody.offsetHeight) / 2
                    : triggerY;
                modalBody.style.transformOrigin = `${transformX}px ${transformY}px`;
            }
            setTimeout(() => {
                modalBody.classList.add("customModal__body_visible");
                modalMask.classList.add("customModal__mask_blackout");
            }, 0);
            const isHaveFocus = (_a = modalBodyRef.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement);
            !isHaveFocus && ((_b = modalBodyRef.current) === null || _b === void 0 ? void 0 : _b.focus());
        }
        return () => {
            if (document) {
                const modalsCount = document.body.querySelectorAll(".customModal").length;
                const drawersCount = document.body.querySelectorAll(".customDrawer").length;
                if (!modalsCount && !drawersCount) {
                    document.body.style.overflowY = "";
                    document.body.style.width = "";
                }
            }
        };
    }, [isShowed]);
    useEffect(() => {
        onRendered && onRendered(isShowed);
    }, [isShowed]);
    const modalHandler = useCallback((e) => {
        const target = e.target;
        if (!target.closest(".customModal__contentWrapper") &&
            modalWrapperRef.current.contains(target) &&
            !isMouseDownInModal.current &&
            maskClosable &&
            onClose) {
            onClose();
        }
        isMouseDownInModal.current = false;
    }, [onClose]);
    const escapeHandler = useCallback((e) => {
        const isCurrentModal = e.currentTarget.contains(document.activeElement);
        e.code === "Escape" && isCurrentModal && onClose && onClose();
    }, [onClose]);
    const mouseDownHandler = useRef((e) => {
        const target = e.target;
        if (target.closest(".customModal__contentWrapper"))
            isMouseDownInModal.current = true;
    });
    return isShowed
        ? createPortal(React.createElement("div", { className: 'customModal' },
            React.createElement("div", { className: 'customModal__mask', ref: modalMaskRef }),
            React.createElement("div", { className: 'customModal__wrapper', onClick: modalHandler, onKeyDown: escapeHandler, ref: modalWrapperRef },
                React.createElement("div", { className: "customModal__body" +
                        (fullscreen ? " customModal__body_fullscrean" : "") +
                        (className ? " " + className : ""), "aria-modal": true, "aria-label": 'Modal content', role: 'dialog', onMouseDown: mouseDownHandler.current, ref: modalBodyRef, tabIndex: 0 },
                    React.createElement("div", { className: "customModal__contentWrapper" +
                            (defaultPaddings ? " customModal__contentWrapper_defaultPaddings" : "") },
                        React.createElement("div", { className: 'customModal__content' },
                            header && (React.createElement("div", { className: 'customModal__content__header' },
                                React.createElement("span", { className: 'customModal__content__header__title typography_h1' }, header),
                                React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', onClick: onClose }))),
                            children),
                        footer && React.createElement("div", { className: 'customModal__footer' }, footer))))), document.body)
        : null;
};
