import "./CustomCheckbox.scss";
import React, { useEffect, useState } from "react";
export const CustomCheckbox = ({ checked, className, darkMode, disabled, id, labelText, onChangeHandler, size = "normal", statusCalb, }) => {
    const [inputChecked, setInputChecked] = useState(!!checked);
    useEffect(() => {
        setInputChecked(!!checked);
    }, [checked]);
    return (React.createElement("div", { className: "customCheckbox" + (className ? ` ${className}` : ""), onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (disabled)
                return;
            setInputChecked(!inputChecked);
            onChangeHandler && onChangeHandler(id, !inputChecked);
            statusCalb && statusCalb(!inputChecked);
        } },
        React.createElement("input", { type: 'checkbox', id: id, checked: inputChecked, disabled: disabled, className: "customCheckbox__input" +
                ` customCheckbox__input_${size}` +
                (labelText ? "" : " customCheckbox__input_withoutText"), onChange: () => { } }),
        React.createElement("label", { htmlFor: id, className: (size === "normal" ? "typography_captionTxt" : "typography_tec_13_medium") + (darkMode ? " blackLabel" : "") }, labelText && labelText)));
};
