import { isBrowser } from "Utils/isBrowser";
export default {
    getItem(key) {
        return isBrowser() ? window.localStorage.getItem(key) : null;
    },
    setItem(key, value) {
        isBrowser() && window.localStorage.setItem(key, value);
    },
    removeItem(key) {
        isBrowser() && window.localStorage.removeItem(key);
    },
};
