export const getGenderDataFromPathname = (pathname) => {
    if (pathname.includes("/men/")) {
        return { gender: "мужское", genderNumber: "1" };
    }
    else if (pathname.includes("/women/")) {
        return { gender: "женское", genderNumber: "2" };
    }
    else {
        return { gender: "все", genderNumber: "0" };
    }
};
