import "./CustomPopover.scss";
import { createPortal } from "react-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
export const CustomPopover = ({ arrowIndent, backgroundColor = "white", children, content, displayDelayMS = 0, getPopoverContainer, onOpenChange, open, overlayClassName, placement, popoverMargin = 12, trigger, }) => {
    const [isPopoverShown, setIsPopoverShown] = useState(false);
    const [popoverContainer, setPopoverContainer] = useState(null);
    const [place, setPlace] = useState(placement || "top");
    const childrenRef = useRef(null);
    const popoverRef = useRef(null);
    const arrowOffset = useRef(arrowIndent || 20);
    useEffect(() => {
        let popoverContainer = getPopoverContainer && getPopoverContainer();
        if (!popoverContainer)
            popoverContainer = childrenRef.current;
        setPopoverContainer(popoverContainer);
    }, []);
    useEffect(() => {
        setIsPopoverShown(!!open);
    }, [open]);
    useEffect(() => {
        var _a, _b;
        const popover = popoverRef.current;
        if (popover) {
            onOpenChange && onOpenChange(isPopoverShown);
            (_a = popoverRef.current) === null || _a === void 0 ? void 0 : _a.style.setProperty("--background-color", `${backgroundColor}`);
            if (isPopoverShown) {
                (trigger === null || trigger === void 0 ? void 0 : trigger.includes("click")) && document.body.addEventListener("click", outsideClickHandler);
                window.addEventListener("resize", reziseHandler);
                (_b = childrenRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            }
            else {
                (trigger === null || trigger === void 0 ? void 0 : trigger.includes("click")) && document.body.removeEventListener("click", outsideClickHandler);
                window.removeEventListener("resize", reziseHandler);
            }
        }
    }, [isPopoverShown]);
    useEffect(() => {
        if (popoverRef.current) {
            validatePlacement();
            initStyles();
        }
    }, [popoverRef.current, place, isPopoverShown]);
    const initStyles = useCallback(() => {
        const popover = popoverRef.current;
        const { offsetWidth: popoverWidth, offsetHeight: popoverHeight } = popover;
        const [mainPosition, secondaryPosition] = place.split(/(?=[B,T,L,R])/);
        const isVertical = mainPosition === "top" || mainPosition === "bottom";
        const isHorizontal = mainPosition === "left" || mainPosition === "right";
        setCoordinates();
        setStyleVariables();
        function setCoordinates() {
            let coordinates = { left: "", right: "", top: "", bottom: "" };
            coordinates[mainPosition] = `-${(isVertical ? popoverHeight : popoverWidth) + popoverMargin}px`;
            if (secondaryPosition) {
                coordinates[secondaryPosition.toLowerCase()] = 0;
            }
            else {
                if (isVertical)
                    coordinates.left = `calc(50% - ${popoverWidth / 2}px)`;
                if (isHorizontal)
                    coordinates.top = `calc(50% - ${popoverHeight / 2}px)`;
            }
            Object.keys(coordinates).map((pos) => (popover.style[pos] = coordinates[pos]));
        }
        function setStyleVariables() {
            var _a, _b, _c, _d;
            const arrowIndent = arrowOffset.current;
            if (isHorizontal) {
                (_a = popoverRef.current) === null || _a === void 0 ? void 0 : _a.style.setProperty("--arrow-x", `${mainPosition === "left" ? popoverWidth : 0}px`);
                const arrowY = secondaryPosition
                    ? secondaryPosition === "Top"
                        ? arrowIndent
                        : popoverHeight - arrowIndent
                    : popoverHeight / 2;
                (_b = popoverRef.current) === null || _b === void 0 ? void 0 : _b.style.setProperty("--arrow-y", `${arrowY}px`);
            }
            else {
                (_c = popoverRef.current) === null || _c === void 0 ? void 0 : _c.style.setProperty("--arrow-y", `${mainPosition === "top" ? popoverHeight : 0}px`);
                const arrowX = secondaryPosition
                    ? secondaryPosition === "Left"
                        ? arrowIndent
                        : popoverWidth - arrowIndent
                    : popoverWidth / 2;
                (_d = popoverRef.current) === null || _d === void 0 ? void 0 : _d.style.setProperty("--arrow-x", `${arrowX}px`);
            }
        }
    }, [place]);
    const validatePlacement = useCallback(() => {
        const popover = popoverRef.current;
        const trigger = childrenRef.current;
        if (!popover || !trigger)
            return;
        popover.style.display = "block";
        let [mainPosition] = place.split(/(?=[B,T,L,R])/);
        const { left, right, top, bottom } = childrenRef.current.getBoundingClientRect();
        const { offsetWidth: triggerWidth, offsetHeight: triggerHeight } = trigger;
        const { offsetWidth: popoverWidth, offsetHeight: popoverHeight } = popover;
        const windowWidth = window.innerWidth - (window.innerWidth - document.documentElement.clientWidth);
        const windowHeight = window.innerHeight;
        const isValidTop = top - popoverHeight - 12 > 0;
        const isValidBottom = bottom + popoverHeight + 12 < windowHeight;
        const isValidLeft = left - popoverWidth - 12 > 0;
        const isValidRight = right + popoverWidth + 12 < windowWidth;
        const adjustedMainPosition = validateMainPosition();
        const adjustedSecondaryPosition = adjustedMainPosition === mainPosition ? validateSecondaryPosition() : "";
        if (adjustedMainPosition + adjustedSecondaryPosition !== place) {
            setPlace((adjustedMainPosition + adjustedSecondaryPosition));
        }
        else {
            return;
        }
        function validateMainPosition() {
            if ((mainPosition === "top" && !isValidTop) ||
                (mainPosition === "bottom" && !isValidBottom) ||
                (mainPosition === "left" && !isValidLeft) ||
                (mainPosition === "right" && !isValidRight)) {
                if (isValidTop)
                    return "top";
                if (isValidBottom)
                    return "bottom";
                if (isValidLeft)
                    return "left";
                return "right";
            }
            else {
                if (placement) {
                    const [defaultMainPosition] = placement.split(/(?=[B,T,L,R])/);
                    if (isValidTop && defaultMainPosition === "top")
                        return "top";
                    if (isValidBottom && defaultMainPosition === "bottom")
                        return "bottom";
                    if (isValidLeft && defaultMainPosition === "left")
                        return "left";
                    if (isValidRight && defaultMainPosition === "right")
                        return "right";
                }
                return mainPosition;
            }
        }
        function validateSecondaryPosition() {
            if (adjustedMainPosition === "top" || adjustedMainPosition === "bottom") {
                if (right - triggerWidth / 2 + popoverWidth / 2 > windowWidth)
                    return "Right";
                if (left + triggerWidth / 2 - popoverWidth / 2 < 0)
                    return "Left";
            }
            else {
                if (top + triggerHeight / 2 - popoverHeight / 2 < 0)
                    return "Bottom";
                if (bottom - triggerHeight / 2 + popoverHeight / 2 > windowHeight)
                    return "Top";
            }
            return "";
        }
    }, [place]);
    const reziseHandler = useCallback(() => {
        validatePlacement();
    }, [place]);
    const outsideClickHandler = useCallback((e) => {
        childrenRef.current && !childrenRef.current.contains(e.target) && setIsPopoverShown(false);
    }, []);
    const overlayHoverHandler = useCallback((e) => {
        if (trigger === null || trigger === void 0 ? void 0 : trigger.includes("hover")) {
            const triggerNode = e.currentTarget;
            setIsPopoverShown(true);
            const mouseLeaveHandler = () => {
                const closeTimeout = setTimeout(() => {
                    setIsPopoverShown(false);
                    triggerNode.removeEventListener("mouseleave", mouseLeaveHandler);
                }, 100);
                const enterHandler = () => {
                    clearTimeout(closeTimeout);
                    triggerNode.removeEventListener("mouseenter", enterHandler);
                };
                triggerNode.addEventListener("mouseenter", enterHandler);
            };
            triggerNode.addEventListener("mouseleave", mouseLeaveHandler);
        }
    }, [trigger]);
    const overlayClickHandler = (parentClickHandler) => {
        return (e) => {
            parentClickHandler && parentClickHandler(e);
            (trigger === null || trigger === void 0 ? void 0 : trigger.includes("click")) && setIsPopoverShown((prev) => !prev);
        };
    };
    return (React.createElement(React.Fragment, null,
        popoverContainer
            ? createPortal(React.createElement("div", { className: `customPopover customPopover_${place} ` +
                    (isPopoverShown ? "customPopover_active " : "") +
                    (overlayClassName ? `${overlayClassName}` : ""), ref: popoverRef, style: { animationDelay: `${displayDelayMS}ms` } },
                React.createElement("div", { className: 'customPopover__arrow' }),
                React.createElement("div", { className: 'customPopover__contentWrapper' },
                    React.createElement("div", { className: 'customPopover__content' }, content))), popoverContainer)
            : null,
        children
            ? React.cloneElement(children, {
                onMouseEnter: overlayHoverHandler,
                onClick: overlayClickHandler(children.props.onClick),
                ref: childrenRef,
                className: "customPopoverTrigger ".concat(children.props.className || ""),
            })
            : null));
};
