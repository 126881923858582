import "./GenderSelection.scss";
import React from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeGender } from "Redux/slices/appSlice";
import { genderList } from "Static/texts";
export const GenderSelection = ({ className }) => {
    const dispatch = useAppDispatch();
    const { genderData } = useAppSelector((state) => state.app);
    return (React.createElement("div", { className: "gender-selection" + (className ? " " + className : "") }, genderList.map((gender) => (React.createElement("p", { className: "typography_tec_13_semiBold gender-selection__item" +
            (genderData.genderNumber === gender.genderNumber ? " gender-selection__item_active" : ""), key: gender.genderNumber, onClick: () => dispatch(changeGender(gender)) }, gender.gender)))));
};
