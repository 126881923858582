import "./BrandsGrid.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { SkeletonsList } from "Components/SkeletonsList/SkeletonsList";
export const BrandsGrid = ({ className, brandsData, isLoading = false }) => {
    const { isFlexGapSupported } = useAppSelector((state) => state.app);
    return (React.createElement("div", { className: "brandsGrid" + (className ? ` ${className}` : ""), "data-is_flex_gap_supported": isFlexGapSupported }, isLoading ? (React.createElement(SkeletonsList, { quantity: 16 },
        React.createElement("div", { className: 'brandsGrid__item_skeleton skeletonLoading' }))) : (brandsData.map((brand) => (React.createElement(NavLink, { className: 'brandsGrid__item', to: `/brands/${brand.url}/`, "aria-label": `${brand.name} - brand logo`, key: brand.id },
        React.createElement("img", { className: 'brandsGrid__item__logo skeletonLoading', src: brand.image, alt: `${brand.name} - brand logo`, loading: 'lazy', onLoad: (e) => e.currentTarget.classList.remove("skeletonLoading"), onError: (e) => e.currentTarget.classList.remove("skeletonLoading") })))))));
};
