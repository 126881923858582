import TagManager from "react-gtm-module";
import { isBrowser } from "Utils/isBrowser";
export const initGTM = () => {
    const isDevMode = process.env.NODE_ENV === "development";
    const isBrowserEnv = isBrowser();
    const isSubDomain = isBrowserEnv && (window.location.host.startsWith("dev") || window.location.host.startsWith("preprod"));
    const tagManagerArgs = {
        gtmId: "GTM-KMNDWNG",
        auth: isDevMode ? "nlzUqI3jMctFMug9zFgbcQ" : "YCFpcSclgeNj052UaLqhsw",
        preview: isDevMode || isSubDomain ? "env-100" : "env-2",
    };
    const localData = [];
    if (!isBrowserEnv)
        return localData;
    if (!isDevMode || !isSubDomain) {
        const role = localStorage.getItem("role");
        if (!role || role === "1") {
            TagManager.initialize(tagManagerArgs);
            localData.push({ originalLocation: location.href });
            const url = sessionStorage.getItem("originalLocation");
            if (url) {
                localData.push({ originalLocation_session: url });
            }
            else {
                sessionStorage.setItem("originalLocation", location.href);
                localData.push({ originalLocation_session: location.href });
            }
        }
    }
    else if (!location.host.startsWith("localhost")) {
        TagManager.initialize(tagManagerArgs);
    }
    const originalData = window.dataLayer;
    if (originalData)
        originalData.push(...localData);
    return originalData || localData;
};
