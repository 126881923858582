import "./CitySelectionInput.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "Hooks/useDebounce";
import { fetchApi } from "Utils/fetchApi";
import { CustomInput } from "Components/CustomInput/CustomInput";
import { Loader } from "Components/Loader/Loader";
export const CitySelectionInput = ({ className, defaultUserCity, disabled, inputId, isLoading, label, message, onBlurHandler, placeholder, searchedCityName, setNewCity, setSearchedCityName, setUserCityId, status, suffixIcon, userCity, }) => {
    const [isHintLoading, setIsHintLoading] = useState(false);
    const [hintsData, setHintsData] = useState([]);
    const [showHintsSheet, setShowHintsSheet] = useState(false);
    const debouncedCityName = useDebounce(searchedCityName, 300);
    const isFirstRender = useRef(true);
    const isCitySelected = useRef(false);
    useEffect(() => {
        if (userCity && !searchedCityName) {
            setSearchedCityName(userCity.city_name);
        }
    }, []);
    useEffect(() => {
        if (debouncedCityName.length > 1 && !isFirstRender.current && !isCitySelected.current) {
            setIsHintLoading(true);
            fetchApi({ method: "GET", url: `/city/search?search=${debouncedCityName}` }).then((res) => {
                if (res.data) {
                    setHintsData(res.data);
                    if (document.activeElement === document.getElementById(inputId || "citySelectionInput__input")) {
                        setShowHintsSheet(true);
                    }
                }
                else {
                    setHintsData([]);
                    setShowHintsSheet(false);
                }
                setIsHintLoading(false);
            });
        }
        isFirstRender.current = false;
        isCitySelected.current = false;
    }, [debouncedCityName]);
    useEffect(() => {
        if (!searchedCityName && hintsData.length > 0) {
            setShowHintsSheet(false);
            setHintsData([]);
            setIsHintLoading(false);
        }
    }, [searchedCityName, hintsData]);
    useEffect(() => {
        disabled && setShowHintsSheet(false);
    }, [disabled]);
    return (React.createElement("div", { className: "citySelectionInput" + (className ? " " + className : "") },
        React.createElement(CustomInput, { id: inputId || "citySelectionInput__input", label: label, disabled: !!defaultUserCity || disabled, placeholder: placeholder, inputValue: defaultUserCity || searchedCityName, autoComplete: 'new-password', onBlurHandler: () => {
                onBlurHandler && onBlurHandler();
                setShowHintsSheet(false);
            }, onChangeHandler: (e) => {
                setSearchedCityName(e.currentTarget.value);
                e.currentTarget.value === "" && setShowHintsSheet(false);
            }, suffix: isLoading || isHintLoading ? React.createElement(Loader, { className: 'citySelectionInput__loader' }) : suffixIcon, status: status, message: message }),
        React.createElement("div", { className: "customSelectHints" +
                (hintsData.length > 0 && showHintsSheet ? " customSelectHints_shown" : "") +
                (label ? " customSelectHints_withLabel" : "") }, hintsData.map((city) => (React.createElement("p", { className: 'typography_tec_16_default customSelectHints__item', key: city.city_id, onMouseDown: (e) => {
                if (document.activeElement === document.getElementById(inputId || "citySelectionInput__input")) {
                    e.preventDefault();
                }
            }, onMouseUp: () => {
                setSearchedCityName(city.city_name);
                setNewCity && setNewCity(city);
                setUserCityId && setUserCityId(city.city_id);
                isCitySelected.current = true;
                setTimeout(() => {
                    var _a;
                    (_a = document.getElementById(inputId || "citySelectionInput__input")) === null || _a === void 0 ? void 0 : _a.blur();
                });
            } },
            React.createElement("span", { className: 'customSelectHints__item__content' },
                React.createElement("span", { className: 'typography_tec_16_default' }, city.city_name),
                React.createElement("span", { className: 'typography_tec_13_medium' }, city.region_name))))))));
};
