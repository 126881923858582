import "./MobileAppQrModal.scss";
import React, { useState } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { cdnHost } from "Static/variables";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { SwitchPanel } from "Components/SwitchPanel/SwitchPanel";
import { CustomModal } from "Components/CustomModal/CustomModal";
export const MobileAppQrModal = ({ isShown, closeHandler }) => {
    const { deviceInfo } = useAppSelector((state) => state.app);
    const isApple = ["iPhone", "iPad", "Macintosh"].includes(deviceInfo.deviceOS);
    const [appType, setAppType] = useState(isApple ? "apple" : "android");
    const switchHandler = (type) => {
        setAppType(type);
    };
    return (React.createElement(CustomModal, { className: 'mobileAppQrModal', fullscreen: true, open: isShown, onClose: closeHandler },
        React.createElement("div", { className: 'customModal__content__header' },
            React.createElement("span", { className: 'typography_tec_title' }, "\u0421\u043A\u0430\u0447\u0438\u0432\u0430\u0439\u0442\u0435 \u043C\u043E\u0431\u0438\u043B\u044C\u043D\u043E\u0435 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435"),
            React.createElement("span", { className: 'customModal__content__header__subTitle typography_bodyTxt' }, "\u041D\u0430\u0432\u0435\u0434\u0438\u0442\u0435 \u043A\u0430\u043C\u0435\u0440\u0443 \u043D\u0430 QR-\u043A\u043E\u0434, \u043F\u0435\u0440\u0435\u0439\u0434\u0438\u0442\u0435 \u043F\u043E \u0441\u0441\u044B\u043B\u043A\u0435, \u0441\u043A\u0430\u0447\u0430\u0439\u0442\u0435 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435."),
            React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', isDefaultClosePosition: true, onClick: closeHandler })),
        React.createElement("div", { className: 'mobileAppQrModal__body customModal__content__body' },
            React.createElement(SwitchPanel, { className: 'mobileAppQrModal__body__switch', clickHandler: switchHandler, controlsData: [
                    { isActive: appType === "apple", txt: "App Store", type: "apple" },
                    { isActive: appType === "android", txt: "Google Play", type: "android" },
                ] }),
            React.createElement("img", { className: 'mobileAppQrModal__body__qr', alt: 'mobile app QR code', src: `${cdnHost}/files/images/qr-${appType === "apple" ? "ios" : "android"}.gif` }))));
};
