import { initGTM } from "Utils/initGTM";
import { isBrowser } from "Utils/isBrowser";
const isBrowserEnv = isBrowser();
export const isDevMode = process.env.NODE_ENV === "development";
export const hostName = "ls.net.ru";
export const cdnHost = `https://cdn.${hostName}`;
export const siteHost = process.env.SITE_HOST ? process.env.SITE_HOST : `https://${isDevMode ? "dev." : ""}${hostName}`;
export const crmUrl = siteHost + "/crm";
export const adminHost = process.env.ADMIN_HOST
    ? process.env.ADMIN_HOST
    : `https://${isDevMode ? "dev." : ""}${hostName}`;
export const apiUrl = process.env.API_URL
    ? process.env.API_URL
    : isDevMode
        ? `https://api2.dev.${hostName}/apix/v2`
        : `https://api2.${hostName}/apix/v2`;
export const botsApiUrl = process.env.API_BOTS_URL ? process.env.API_BOTS_URL : apiUrl;
export const dataLayer = initGTM();
export const _tmr = isBrowserEnv ? window._tmr || [] : [];
export const mailRu_TrackerId = isDevMode ? "3209890" : "3263248";
export const WA_number = "79107912931";
export const YMerchantId = "d3e95488-a7ad-4854-a1fa-a76e098bfd22";
