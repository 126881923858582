import "./Label.scss";
import React from "react";
export const Label = ({ className, labelData, isAdaptive = true, isStack = false, size }) => {
    return labelData ? (React.createElement("div", { className: "label" +
            (className ? ` ${className}` : "") +
            (size === "small" ? " label_small" : "") +
            (isAdaptive && !size ? " label_adaptive" : "") +
            (isStack ? " label_stack" : ""), style: { backgroundColor: labelData.color } },
        React.createElement("span", null, labelData.text))) : null;
};
