import "./NewsSubscription.scss";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { changeSnackbarData } from "Redux/slices/appSlice";
import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { isItEmail } from "Utils/isItEmail";
import { fetchApi } from "Utils/fetchApi";
import { ArrowIcon } from "Svg/ArrowIcon";
import { CustomInput } from "Components/CustomInput/CustomInput";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { CustomCheckbox } from "Components/CustomCheckbox/CustomCheckbox";
import { Loader } from "Components/Loader/Loader";
export const NewsSubscription = ({ checkedGenders, email, isSubscribed, onChangeEmail, onChangeGenders, onChangeSubscribeState, }) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.userInfo);
    useEffect(() => {
        onChangeEmail((user === null || user === void 0 ? void 0 : user.email) || "");
    }, [user]);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const onChangeEmailInputValue = (e) => {
        onChangeEmail(e.target.value);
        !isValidEmail && setIsValidEmail(true);
    };
    const onRemoveEmail = () => {
        onChangeEmail("");
        setIsValidEmail(true);
    };
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = () => {
        if (!isItEmail(email)) {
            setIsValidEmail(false);
            return;
        }
        setIsLoading(true);
        fetchApi({
            method: "POST",
            url: "/site/subscribe/email",
            body: { email, sex: checkedGenders.length === 2 ? "0" : checkedGenders[0] },
        })
            .then(() => onChangeSubscribeState(true))
            .catch((err) => dispatch(changeSnackbarData({ message: err.message || err, status: "error" })))
            .finally(() => setIsLoading(false));
    };
    if (isSubscribed)
        return (React.createElement("div", { className: 'newsSubscription' },
            React.createElement("p", { className: 'typography_tec_16_body' }, "\u0421\u043F\u0430\u0441\u0438\u0431\u043E, \u0447\u0442\u043E \u043F\u043E\u0434\u043F\u0438\u0441\u0430\u043B\u0438\u0441\u044C \u043D\u0430 \u043D\u0430\u0448\u0443 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443! \u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D \u0432\u0430\u043C \u043D\u0430 \u043F\u043E\u0447\u0442\u0443! \u0421\u0432\u043E\u044E \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u0432\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0440\u0435\u0433\u0443\u043B\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0432 \u043B\u0438\u0447\u043D\u043E\u043C \u043A\u0430\u0431\u0438\u043D\u0435\u0442\u0435 \u0432 \u0440\u0430\u0437\u0434\u0435\u043B\u0435 \u00AB\u041F\u043E\u0434\u043F\u0438\u0441\u043A\u0438\u00BB. \u0423\u0434\u0430\u0447\u043D\u044B\u0445 \u043F\u043E\u043A\u0443\u043F\u043E\u043A!")));
    return (React.createElement("div", { className: 'newsSubscription' },
        React.createElement(CustomInput, { autoComplete: 'email', className: 'newsSubscription__input', id: 'newsSubscription__email', inputValue: email, message: isValidEmail ? "" : "Неверный формат. Формат email: login@domain", onChangeHandler: onChangeEmailInputValue, placeholder: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0432\u0430\u0448 email', status: isValidEmail ? undefined : "error", type: 'email', prefix: React.createElement(CloseIcon, { onClick: onRemoveEmail }), suffix: React.createElement("button", { className: 'newsSubscription__input__subscribeBtn customBtn customBtn_contained', disabled: isLoading, onClick: onSubmit }, isLoading ? React.createElement(Loader, null) : React.createElement(ArrowIcon, null)) }),
        React.createElement("div", { className: 'newsSubscription__genders' }, ["1", "2"].map((gender) => (React.createElement(CustomCheckbox, { key: gender, id: `newsSubscription__checkbox_${gender}`, checked: checkedGenders.includes(gender), labelText: gender === "1" ? "Для него" : "Для нее", onChangeHandler: (_, newStatus) => onChangeGenders(newStatus, gender) })))),
        React.createElement("p", { className: 'newsSubscription__convention typography_tec_12_medium' },
            `Подписываясь на рассылку, вы соглашаетесь с `,
            React.createElement(NavLink, { to: '/service/consentToProcessing/', target: '_blank', rel: 'noopener noreferrer' }, "\u043F\u043E\u043B\u0438\u0442\u0438\u043A\u043E\u0439 \u043A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438"))));
};
