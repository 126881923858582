import "./StoreContactInfo.scss";
import React from "react";
import { SocialIcon } from "Components/SocialIcon/SocialIcon";
const linksProps = { target: "_blank", rel: "noopener noreferrer" };
export const StoreContactInfo = () => {
    return (React.createElement("div", { className: 'storeContactInfo' },
        React.createElement("div", { className: 'storeContactInfo__phone' },
            React.createElement("a", { className: 'typography_h2', href: 'tel:88005559682' }, "8 800 555-96-82"),
            React.createElement("time", { className: 'typography_tec_13_medium', dateTime: 'Mo-Su, 09:00-22:00' }, "\u0415\u0436\u0435\u0434\u043D\u0435\u0432\u043D\u043E \u0441 09:00 \u0434\u043E 22:00")),
        React.createElement("div", { className: 'storeContactInfo__social' },
            React.createElement(SocialIcon, { type: 'vk', href: 'https://vk.com/lsboutiq', ...linksProps }),
            React.createElement(SocialIcon, { type: 'telegram', href: 'https://t.me/lsnetru', ...linksProps }))));
};
