var _a;
import { createSlice } from "@reduxjs/toolkit";
import { getBrowserName } from "Utils/getBrowserName";
import { getWindowWidth } from "Utils/getWindowWidth";
import localStorage from "Utils/localStorage";
import { isBrowser } from "Utils/isBrowser";
import { getGenderDataFromPathname } from "Utils/getGenderDataFromLocation";
import { isValidGenderNumber } from "Utils/isValidGenderNumber";
import { personalAreaRoutes } from "Static/personalAreaRoutes";
const isBrowserEnv = isBrowser();
let initialGender = { gender: "все", genderNumber: "0" };
let localGender = localStorage.getItem("genderData");
if (localGender && !isValidGenderNumber((_a = JSON.parse(localGender)) === null || _a === void 0 ? void 0 : _a.genderNumber)) {
    localGender = JSON.stringify(initialGender);
    localStorage.setItem("genderData", JSON.stringify(initialGender));
}
const pathnameGender = isBrowserEnv ? getGenderDataFromPathname(location.pathname) : initialGender;
if (pathnameGender.genderNumber !== "0") {
    initialGender = pathnameGender;
    localStorage.setItem("genderData", JSON.stringify(pathnameGender));
}
else if (localGender) {
    initialGender = JSON.parse(localGender);
}
const savedCity = localStorage.getItem("city");
const initialCity = savedCity ? JSON.parse(savedCity) : { id: "992", name: "Москва" };
const savedNotifications = localStorage.getItem("notifications");
const initialNotifications = savedNotifications ? JSON.parse(savedNotifications) : [];
const localUnvisitedOrders = localStorage.getItem("unvisitedOrders");
const initialUnvisitedOrders = localUnvisitedOrders ? +localUnvisitedOrders : 0;
const windowWidth = isBrowserEnv ? getWindowWidth() : 1920;
const initialState = {
    AB_tests: null,
    appNotify: false,
    breakpointMatches: {
        1440: windowWidth <= 1440,
        1080: windowWidth <= 1080,
        960: windowWidth <= 960,
        768: windowWidth <= 768,
        600: windowWidth <= 600,
        374: windowWidth <= 374,
    },
    city: initialCity,
    clientWidgetSubClass: "",
    defaultFooterLinks: [],
    deviceInfo: { deviceType: "", deviceOS: "", browserName: "" },
    discountIncrease: undefined,
    footerLinks: [],
    genderData: initialGender,
    isAuthModalShown: false,
    isFlexGapSupported: false,
    isFooterIntersected: false,
    isFriendlyIp: true,
    isHeaderShown: isBrowserEnv
        ? location.pathname.startsWith("/checkout") ||
            (personalAreaRoutes.includes(location.pathname) && windowWidth <= 960)
            ? false
            : true
        : true,
    mainMenu: [],
    notifications: initialNotifications,
    optionalBlocks: [],
    promoActions: [],
    showFooter: true,
    snackbarData: null,
    unvisitedOrders: initialUnvisitedOrders,
};
export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        saveMainMenu(state, action) {
            state.mainMenu = action.payload;
        },
        changeGender(state, action) {
            const newGender = action.payload;
            if (!newGender) {
                state.genderData = initialGender;
                localStorage.setItem("genderData", JSON.stringify(initialGender));
            }
            else if (state.genderData.genderNumber !== newGender.genderNumber) {
                state.genderData = newGender;
                localStorage.setItem("genderData", JSON.stringify(newGender));
            }
        },
        identifyDevice(state) {
            const userDeviceArray = [
                { device: "Android", platform: /Android/ },
                { device: "iPad", platform: /iPad/ },
                { device: "iPhone", platform: /iPhone/ },
                { device: "Linux", platform: /Linux/ },
                { device: "Macintosh", platform: /Macintosh/ },
                { device: "Symbian", platform: /Symbian/ },
                { device: "Tablet OS", platform: /Tablet OS/ },
                { device: "Windows Phone", platform: /Windows Phone/ },
                { device: "Windows", platform: /Windows NT/ },
            ];
            const currentPlatform = window.navigator ? window.navigator.userAgent : "";
            let device = "";
            for (let i in userDeviceArray) {
                if (userDeviceArray[i].platform.test(currentPlatform)) {
                    device = userDeviceArray[i].device;
                    break;
                }
            }
            if (device === "Macintosh" && "ontouchend" in document) {
                device = "iPad";
            }
            let deviceInfo = {
                deviceType: device ? (["Linux", "Windows", "Macintosh"].includes(device) ? "desktop" : "mobile") : "unknown",
                deviceOS: device || "unknown",
                browserName: getBrowserName(),
            };
            localStorage.setItem("deviceInfo", JSON.stringify(deviceInfo));
            state.deviceInfo = deviceInfo;
        },
        changeCity(state, action) {
            localStorage.setItem("city", JSON.stringify(action.payload));
            state.city = action.payload;
        },
        changeHeaderVisibility(state, action) {
            state.isHeaderShown = action.payload;
        },
        changeFooterVisibility(state, action) {
            state.showFooter = action.payload;
        },
        changeAuthModalVisibility(state, action) {
            state.isAuthModalShown = action.payload;
        },
        changeAppNotifyVisibility(state, action) {
            state.appNotify = action.payload;
        },
        addNotification(state, action) {
            const notificationData = action.payload;
            const notificationList = [...state.notifications];
            const newNotification = { id: notificationData.id, content: notificationData.content };
            const currentCategory = notificationList.find((prevNotification) => prevNotification.category === notificationData.category);
            if (currentCategory) {
                currentCategory.value.push(newNotification);
                const idx = notificationList.findIndex((el) => el.category === notificationData.category);
                notificationList.splice(idx, 1, currentCategory);
            }
            else {
                notificationList.push({ category: notificationData.category, value: [newNotification] });
            }
            localStorage.setItem("notifications", JSON.stringify(notificationList));
            state.notifications = notificationList;
        },
        removeNotificaionCategory(state, action) {
            const filterredNotifications = state.notifications.filter((prevNotification) => prevNotification.category !== action.payload);
            localStorage.setItem("notifications", JSON.stringify(filterredNotifications));
            state.notifications = filterredNotifications;
        },
        rewriteNotifications(state, action) {
            action.payload.withLocalSave && localStorage.setItem("notifications", JSON.stringify(action.payload.data));
            state.notifications = action.payload.data;
        },
        saveDefaultFooterLinks(state, action) {
            state.defaultFooterLinks = action.payload;
        },
        changeFooterLinks(state, action) {
            state.footerLinks = action.payload.length > 0 ? action.payload : state.defaultFooterLinks;
        },
        saveBreakpointMatches(state, action) {
            state.breakpointMatches = { ...state.breakpointMatches, ...action.payload };
        },
        saveOptionalBlocks(state, action) {
            state.optionalBlocks = action.payload;
        },
        changeFooterIntersectionStatus(state, action) {
            state.isFooterIntersected = action.payload;
        },
        changeFlexGapSupportStatus(state, action) {
            state.isFlexGapSupported = action.payload;
        },
        rewriteUnvisitedOrders(state, action) {
            localStorage.setItem("unvisitedOrders", action.payload.toString());
            state.unvisitedOrders = action.payload;
        },
        changeClientWidgetSubClass(state, action) {
            state.clientWidgetSubClass = action.payload;
        },
        changeSnackbarData(state, action) {
            if (JSON.stringify(state.snackbarData) === JSON.stringify(action.payload))
                return;
            state.snackbarData = action.payload;
        },
        changeIpFriendlinessStatus(state, action) {
            state.isFriendlyIp = action.payload;
        },
        saveExperiments(state, action) {
            state.AB_tests = action.payload;
        },
        savePromoActions(state, action) {
            state.promoActions = action.payload;
        },
        changeDiscountIncrease(state, action) {
            state.discountIncrease = action.payload;
        },
    },
});
export const { addNotification, changeAppNotifyVisibility, changeAuthModalVisibility, changeCity, changeClientWidgetSubClass, changeFlexGapSupportStatus, changeFooterIntersectionStatus, changeFooterLinks, changeFooterVisibility, changeGender, changeHeaderVisibility, changeIpFriendlinessStatus, changeSnackbarData, identifyDevice, removeNotificaionCategory, rewriteNotifications, rewriteUnvisitedOrders, saveBreakpointMatches, saveDefaultFooterLinks, saveExperiments, saveMainMenu, saveOptionalBlocks, savePromoActions, changeDiscountIncrease, } = appSlice.actions;
export default appSlice.reducer;
