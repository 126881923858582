import "./SocialIcon.scss";
import React from "react";
import { OKIcon } from "Svg/OKIcon";
import { TelegramIcon } from "Svg/TelegramIcon";
import { VKIcon } from "Svg/VKIcon";
export const SocialIcon = ({ type, onClick, href, ...props }) => {
    const isLink = (href === null || href === void 0 ? void 0 : href.length) && !onClick;
    const Icon = type === "ok" ? (React.createElement(OKIcon, { className: 'socialIcon_ok' })) : type === "vk" ? (React.createElement(VKIcon, { className: 'socialIcon_vk' })) : (React.createElement(TelegramIcon, { className: 'socialIcon_tg' }));
    return isLink ? (React.createElement("a", { className: 'socialIcon customBtn customBtn_icon customBtn_iconLarge', href: href, ...props }, Icon)) : (React.createElement("button", { className: 'socialIcon customBtn customBtn_icon customBtn_iconLarge', onClick: () => onClick && onClick(type), ...props }, Icon));
};
