import "./VerificationCode.scss";
import React, { useEffect, useState } from "react";
import { CustomInput } from "Components/CustomInput/CustomInput";
export const VerificationCode = ({ className, codeHandler, isСodeUnderVerifying }) => {
    const [firstCodeNumber, setFirstCodeNumber] = useState("");
    const [secondCodeNumber, setSecondCodeNumber] = useState("");
    const [thirdCodeNumber, setThirdCodeNumber] = useState("");
    const [fourthCodeNumber, setFourthCodeNumber] = useState("");
    useEffect(() => {
        var _a;
        (_a = document.getElementById("firstCodeNumber")) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    const changeFocus = (nextInpuId) => {
        var _a;
        nextInpuId && ((_a = document.getElementById(nextInpuId)) === null || _a === void 0 ? void 0 : _a.focus());
    };
    useEffect(() => {
        const code = firstCodeNumber + secondCodeNumber + thirdCodeNumber + fourthCodeNumber;
        code.length === 4 && codeHandler(code);
    }, [firstCodeNumber, secondCodeNumber, thirdCodeNumber, fourthCodeNumber]);
    const backToPrevInput = (e, setValue, prevInputId) => {
        var _a;
        if (e.key === "Backspace") {
            e.preventDefault();
            setValue("");
            prevInputId && ((_a = document.getElementById(prevInputId)) === null || _a === void 0 ? void 0 : _a.focus());
        }
    };
    return (React.createElement("div", { className: "verificationCode" + (className ? ` ${className}` : "") }, [
        {
            id: "firstCodeNumber",
            value: firstCodeNumber,
            setValue: setFirstCodeNumber,
            nextId: "secondCodeNumber",
            prevId: "",
        },
        {
            id: "secondCodeNumber",
            value: secondCodeNumber,
            setValue: setSecondCodeNumber,
            nextId: "thirdCodeNumber",
            prevId: "firstCodeNumber",
        },
        {
            id: "thirdCodeNumber",
            value: thirdCodeNumber,
            setValue: setThirdCodeNumber,
            nextId: "fourthCodeNumber",
            prevId: "secondCodeNumber",
        },
        {
            id: "fourthCodeNumber",
            value: fourthCodeNumber,
            setValue: setFourthCodeNumber,
            nextId: "",
            prevId: "thirdCodeNumber",
        },
    ].map(({ id, value, setValue, nextId, prevId }) => (React.createElement("div", { className: 'verificationCode__item', key: id },
        React.createElement(CustomInput, { autoComplete: 'new-password', autoFocus: id === "firstCodeNumber", disabled: isСodeUnderVerifying, id: id, inputValue: value, integerOnly: true, type: 'number', onChangeHandler: (e) => {
                const code = e.nativeEvent.data || e.currentTarget.value;
                if (code.length === 4) {
                    setFirstCodeNumber(code[0]);
                    setSecondCodeNumber(code[1]);
                    setThirdCodeNumber(code[2]);
                    setFourthCodeNumber(code[3]);
                    changeFocus("fourthCodeNumber");
                }
                else {
                    changeFocus(nextId);
                    setValue(code);
                }
            }, onKeyDownHandler: (e) => backToPrevInput(e, setValue, prevId) }))))));
};
