export const getUniqueAndConcatedLocalData = (localProducts) => {
    if (localProducts.length === 0)
        return [];
    let result = [];
    localProducts.forEach((localProduct) => {
        if (result.length === 0) {
            result.push(localProduct);
        }
        else if (result[result.length - 1].productId === localProduct.productId) {
            result[result.length - 1] = {
                productId: localProduct.productId,
                sizes: [...result[result.length - 1].sizes, ...localProduct.sizes],
            };
        }
        else {
            result.push(localProduct);
        }
    });
    return result;
};
