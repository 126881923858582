import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    postsState: { year: "ПО ПОРЯДКУ", page: "1" },
};
export const postsSlice = createSlice({
    name: "posts",
    initialState,
    reducers: {
        savePostsSortAndPage(state, action) {
            state.postsState = action.payload;
        },
    },
});
export const { savePostsSortAndPage } = postsSlice.actions;
export default postsSlice.reducer;
